import {
  Container,
  ContainerProps,
  Link,
  Stack,
  styled,
  Typography,
  useTheme,
} from '@mui/material';
import { FC } from 'react';
import { NavLink } from 'react-router-dom';
import { PATHS } from 'src/constants';

const FooterWrapper = styled(Container)(
  ({ theme }) => `
        margin-top: ${theme.spacing(4)};
`,
);

const Footer: FC<ContainerProps> = ({ ...rest }) => {
  const theme = useTheme();

  return (
    <FooterWrapper className="footer-wrapper" {...rest}>
      <Stack justifyContent="center" spacing={1}>
        <Typography color={theme.functionalColors.label} fontSize={10}>
          By using this product, you acknowledge having read the{' '}
          <Link
            component={NavLink}
            sx={{ color: theme => theme.palette.common.white, textDecoration: 'underline' }}
            to={PATHS.PRIVACY_POLICY}
          >
            privacy policy
          </Link>
          &nbsp;and&nbsp;
          <Link
            component={NavLink}
            sx={{ color: theme => theme.palette.common.white, textDecoration: 'underline' }}
            to={PATHS.DISCLAIMER}
          >
            legal disclaimer
          </Link>
        </Typography>
        <Stack
          direction="row"
          justifyContent="space-between"
          pb={4}
          textAlign={{ xs: 'center', md: 'left' }}
        >
          <Typography color={theme.functionalColors.label} variant="subtitle1">
            &copy; 2023 - AltLayer Rollup Launcher
          </Typography>
          <Typography
            color={theme.functionalColors.label}
            sx={{
              pt: 0,
            }}
            variant="subtitle1"
          >
            A product of{' '}
            <Link href="https://altlayer.io/" rel="noopener noreferrer" target="_blank">
              altlayer.io
            </Link>
          </Typography>
        </Stack>
      </Stack>
    </FooterWrapper>
  );
};

export default Footer;
