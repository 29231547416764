import { Fragment, JsonFragment } from '@ethersproject/abi';
import { utils } from 'ethers';
import { rest } from 'msw';
import L1ERC721MockAbi from 'src/contracts/abi/L1ERC721Mock.json';
import FinalizerAbi from 'src/contracts/abi/L1Finalizer.json';
import L2ERC721MockAbi from 'src/contracts/abi/L2ERC721Mock.json';
import { erc20ABI } from 'wagmi';

const getContractFnSigMap = (abi: string | ReadonlyArray<Fragment | JsonFragment | string>) => {
  const iface = new utils.Interface(abi);

  return Object.keys(iface.functions)?.reduce(
    (acc, key) => ({ ...acc, [iface.getSighash(key)]: key }),
    {},
  );
};

const erc20FnSigs = getContractFnSigMap(erc20ABI);
const l1Erc721FnSigs = getContractFnSigMap(L1ERC721MockAbi);
const l2Erc721FnSigs = getContractFnSigMap(L2ERC721MockAbi);
const finalizerFnSigs = getContractFnSigMap(FinalizerAbi);
const fnSigMap: Record<string, string> = {
  ...erc20FnSigs,
  ...l1Erc721FnSigs,
  ...l2Erc721FnSigs,
  ...finalizerFnSigs,
};

console.log('fn signature map: ', fnSigMap);

// const randomlyFailRpcCalls = (res, ctx) => {
//   // 1 = fail all rpc calls, 0.2 = fail 20% of rpc calls
//   const threshold = 1;

//   const rand = Math.random();

//   if (rand <= threshold) {
//     return res(ctx.delay(1000), ctx.status(500), null);
//   }
// };

const fnsToMock = ['symbol()', 'decimals()', 'queryIDs(uint256,uint256)'];
const fetchRpcRateLimited = rest.post('*rpc.sepolia.org*', async (req, res, ctx) => {
  const reqBody = await req.json();

  const fnSig = reqBody.params[0]?.data?.substring(0, 10);

  if (reqBody.method === 'eth_call' && fnsToMock?.includes(fnSigMap[fnSig])) {
    return res(ctx.delay(1000), ctx.status(500), null);
  }

  console.debug(`${reqBody.method} with fn signature ${fnSigMap[fnSig] || fnSig} not mocked.`);

  return req.passthrough();
  // Uncomment this line to randomly fail 1 in every N calls
  // return randomlyFailRpcCalls(res, ctx) || req.passthrough();
});

export default fetchRpcRateLimited;
