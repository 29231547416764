import { SvgIcon } from '@mui/material';

export default function IconFreeMint(props: any) {
  return (
    <SvgIcon height="40px" viewBox="0 0 40 40" width="40px" {...props}>
      <path
        clipRule="evenodd"
        d="M0.800003 20.0008C0.800003 30.5883 9.4125 39.2008 20 39.2008C30.5875 39.2008 39.2 30.5883 39.2 20.0008C39.2 9.41328 30.5875 0.800781 20 0.800781C9.4125 0.800781 0.800003 9.41328 0.800003 20.0008ZM13.4375 16.2508C13.4375 16.7683 13.0175 17.1883 12.5 17.1883C11.9825 17.1883 11.5625 16.7683 11.5625 16.2508C11.5625 15.7333 11.9825 15.3133 12.5 15.3133C13.0175 15.3133 13.4375 15.7333 13.4375 16.2508ZM27.5 15.3133C26.9825 15.3133 26.5625 15.7333 26.5625 16.2508C26.5625 16.7683 26.9825 17.1883 27.5 17.1883C28.0175 17.1883 28.4375 16.7683 28.4375 16.2508C28.4375 15.7333 28.0175 15.3133 27.5 15.3133ZM20 1.70078C9.90875 1.70078 1.7 9.90953 1.7 20.0008C1.7 30.0908 9.90875 38.3008 20 38.3008C30.09 38.3008 38.3 30.092 38.3 20.0008C38.3 9.90953 30.09 1.70078 20 1.70078ZM12.05 22.5008C12.05 26.8833 15.6163 30.4508 20 30.4508C24.3838 30.4508 27.95 26.8845 27.9513 22.5008H27.05C27.05 26.3895 23.8875 29.5508 20 29.5508C16.1125 29.5508 12.95 26.3883 12.95 22.5008H12.05Z"
        fill="#AFB0CC"
        fillRule="evenodd"
      />
    </SvgIcon>
  );
}
