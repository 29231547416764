/* eslint-disable no-template-curly-in-string */
import { BigNumber, utils } from 'ethers';
import get from 'lodash/get';
import * as yup from 'yup';

yup.setLocale({
  mixed: {
    required: 'This field is required',
    notType: ({ type }) => {
      return type === 'number' ? 'Please enter a numeric value' : `Please enter a ${type}`;
    },
  },
  string: {
    min: 'Please enter at least ${min} characters',
    max: 'Please enter at most ${max} characters',
    email: 'Please enter a valid email address',
    url: 'Please enter a valid url',
  },
  number: {
    min: ({ min }) => `Please enter a number greater than or equal to ${utils.commify(min)}`,
    max: ({ max }) => `Please enter a number smaller than or equal to ${utils.commify(max)}`,
    integer: 'Please enter an integer value',
  },
});

yup.addMethod(
  yup.string,
  'bigNumber',
  function bigNumber(maxValue = '340282366920938463463374607431768211455', errorMsg) {
    return this.test(
      'is-big-number',
      errorMsg || 'Please enter an integer between 1 and 2^128 inclusive',
      function (value: string) {
        try {
          const bn = BigNumber.from(value);

          if (bn?.lte?.(BigNumber.from(maxValue)) && bn?.gte?.(BigNumber.from('1'))) {
            return true;
          }

          return this.createError();
        } catch (err) {
          return value === undefined || this.createError();
        }
      },
    );
  },
);

yup.addMethod(yup.string, 'address', function address() {
  return this.test('is-address', 'Please enter a valid address', function (value: string) {
    try {
      utils.getAddress(value);

      return true;
    } catch (err) {
      return value === undefined || this.createError();
    }
  });
});

yup.addMethod(
  yup.string,
  'tokenBalance',
  function tokenBalance(decimals = 18, allowZeroValue = false) {
    return this.matches(/(^\d+$|^\d+\.\d+$)/i, 'Please enter a numeric value')
      .matches(
        RegExp(`(^\\d+$|^\\d+.\\d{1,${decimals}}$)$`, 'i'),
        'Exceeded maximum number of decimal places allowed',
      )
      .matches(
        allowZeroValue ? /.*/i : /[1-9]+[0-9]*(\.[0-9]+)?/i,
        'Please enter a non-zero value',
      );
  },
);

yup.addMethod(
  yup.string,
  'isRegex',
  function isRegex(message = 'Please enter a valid javascript regular expression e.g. /abc/') {
    return this.test('is-regex', message, function (value) {
      const closingRegexIndex = value?.lastIndexOf('/');
      const expression = value?.substring(1, closingRegexIndex);
      const flags = value?.substring(closingRegexIndex + 1);

      try {
        RegExp(expression, flags);

        return true; // valid regex
      } catch (err) {
        return this.createError();
      }
    });
  },
);

yup.addMethod(
  yup.mixed,
  'uniqueInArray',
  function (filterCondition = (item: any, fieldValue: string) => item === fieldValue, pathToArray) {
    return this.test('uniqueIn', 'Duplicate values are not allowed', function (value, context) {
      if (pathToArray) {
        const allFormValues = (this as any).from[(this as any).from?.length - 1]?.value;
        const array = get(allFormValues, pathToArray);

        return array.filter((item: any) => filterCondition(item, value)).length < 2;
      }

      return context?.parent?.filter((item: any) => filterCondition(item, value)).length < 2;
    });
  },
);

yup.addMethod(yup.array, 'unique', function (message, mapper = (a: any) => a) {
  return this.test('unique', message, function (list) {
    return list.length === new Set(list.map(mapper)).size;
  });
});

yup.addMethod(yup.number, 'step', function (stepValue, message) {
  return this.test(
    'step',
    message || `Only multiples of ${stepValue} are allowed`,
    function (value) {
      return Number(value) % stepValue === 0;
    },
  );
});
