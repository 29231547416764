import { Box, Snackbar, Stack } from '@mui/material';
import { createContext, FC, ReactNode, useCallback, useContext, useMemo, useState } from 'react';
import { RpcError } from 'wagmi';

import Toast from './Toast';

const getKey = (() => {
  const MIN = 0;
  let index = MIN;

  return () => {
    const now = new Date().getTime();

    return `${++index}_${now}`;
  };
})();

export enum ALERT_TYPE {
  ERROR = 'ERROR',
  DEBUG = 'DEBUG',
  SUCCESS = 'SUCCESS',
  INFO = 'INFO',
  WARNING = 'WARNING',
  PENDING = 'PENDING',
}

export type IAddAlert = (alert: Omit<Alert, 'key'> & { error?: Error }) => string;
type RemoveAlert = (key: string) => void;

interface Alert {
  type: ALERT_TYPE;
  timeout?: number;
  key: string;
  title: string;
  link?: {
    url: string;
    label: string;
  };
  desc?: string;
}

type AlertsContext = {
  addAlert: IAddAlert;
  showReadContractError: (resourceName: string, error: RpcError) => string;
};

export const AlertsContext = createContext<AlertsContext>({} as AlertsContext);

export const useAlerts = () => {
  return useContext(AlertsContext);
};

export const AlertsProvider: FC<{ children: ReactNode }> = ({ children }) => {
  const [alerts, setAlerts] = useState<Alert[]>([]);

  const addAlert: IAddAlert = useCallback(
    ({ desc, error, link, timeout: _timeout, title: _title, type }) => {
      const defaultTimeout = type === ALERT_TYPE.ERROR ? 20000 : 6000;
      const timeout = _timeout || defaultTimeout;

      const title = _title?.charAt(0)?.toUpperCase() + _title?.substring(1);

      if (error) {
        console.error(error);
      }

      const key = getKey();

      setAlerts(state => {
        return [{ type, title, timeout, key, link, desc }, ...state];
      });
      setTimeout(() => {
        // remove alert after timeout
        setAlerts(state => state.filter(cur => cur.key !== key));
      }, timeout);

      return key;
    },
    [],
  );

  const removeAlert: RemoveAlert = key => {
    setAlerts(state => state.filter(cur => cur.key !== key));
  };

  const filteredAlerts = useMemo(() => {
    if (process.env.NODE_ENV === 'development') {
      return alerts;
    }

    return alerts.filter(cur => cur.type !== ALERT_TYPE.DEBUG);
  }, [alerts]);

  const showReadContractError = useCallback(
    (resourceName: string, error: RpcError) => {
      const key = addAlert({
        title: `Failed to fetch ${resourceName}`,
        type: ALERT_TYPE.WARNING,
        desc: `Status: ${error?.message?.match(/"status":(\d{3})/im)?.[1]}`,
        error,
      });

      return key;
    },
    [addAlert],
  );

  return (
    <AlertsContext.Provider value={{ addAlert, showReadContractError }}>
      {children}

      <Snackbar
        anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
        open={filteredAlerts?.length > 0}
      >
        <Box>
          <Stack direction="column" spacing={1}>
            {filteredAlerts?.map(cur => (
              <Toast
                desc={cur.desc}
                duration={cur.timeout}
                key={cur.key}
                link={cur.link}
                onClose={() => {
                  removeAlert(cur.key);
                }}
                title={cur.title}
                type={cur.type}
              />
            ))}
          </Stack>
        </Box>
      </Snackbar>
    </AlertsContext.Provider>
  );
};
