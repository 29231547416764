import { Stack } from '@mui/material';
import { connectorsForWallets, darkTheme, RainbowKitProvider } from '@rainbow-me/rainbowkit';
import {
  coinbaseWallet,
  metaMaskWallet,
  rainbowWallet,
  walletConnectWallet,
} from '@rainbow-me/rainbowkit/wallets';
import { createContext, useContext, useMemo, useState } from 'react';
import { supportedChains } from 'src/helpers/wagmi';
import { CustomChain } from 'src/types/global';
import { Chain, configureChains, createClient, WagmiConfig } from 'wagmi';
import { publicProvider } from 'wagmi/providers/public';

interface Context {
  chains: Chain[];
  setChains: React.Dispatch<React.SetStateAction<ReturnType<typeof configureChains>>>;
}

export const WagmiContext = createContext<Context>({} as Context);

export const useWagmi = () => {
  return useContext(WagmiContext);
};

const appName = 'AltLayer Rollup Bridge';

const theme = darkTheme();

theme.colors.accentColor = '#6667ab';
theme.colors.profileForeground = '#373741';
theme.colors.modalBackground = '#373741';
theme.radii.modal = '16px';

const projectId = window.appConfig?.integrations?.walletConnectProjectId;

export const WagmiProvider: React.FC<{ children: React.ReactNode }> = ({ children }) => {
  const [{ chains, provider }, setChains] = useState(
    configureChains(supportedChains as CustomChain[], [publicProvider()]),
  );

  const connectors = useMemo(
    () =>
      connectorsForWallets([
        {
          groupName: 'Recommended',
          wallets: [
            metaMaskWallet({ chains, projectId }),
            rainbowWallet({ chains, projectId }),
            walletConnectWallet({
              chains,
              options: {
                bridge: 'wss://relay.walletconnect.com',
                projectId,
              },
              projectId,
            }),
            coinbaseWallet({ chains, appName }),
          ],
        },
      ]),
    [chains],
  );

  const wagmiConfig = useMemo(
    // Reinitialize the wagmi client if new configs are set (Usually when new networks are added via setChainsConfig)
    () =>
      createClient({
        autoConnect: true,
        connectors,
        provider,
      }),
    [provider, connectors],
  );

  return (
    <WagmiContext.Provider value={{ chains, setChains }}>
      <WagmiConfig client={wagmiConfig}>
        <RainbowKitProvider chains={chains} showRecentTransactions theme={theme}>
          <Stack sx={{ minHeight: '100vh' }}>{children}</Stack>
        </RainbowKitProvider>
      </WagmiConfig>
    </WagmiContext.Provider>
  );
};
