import { SvgIcon } from '@mui/material';

export default function IconBridge(props: any) {
  return (
    <SvgIcon height="20px" viewBox="0 0 20 20" width="20px" {...props}>
      <path
        clipRule="evenodd"
        d="M15.0139 9.2783C14.9092 6.82552 12.8876 4.86914 10.4091 4.86914H2V6.36005H10.4091L10.5802 6.36466C12.2227 6.45348 13.5273 7.81351 13.5273 9.47823V10.6146L13.5318 10.7933C13.6248 12.6282 15.142 14.0873 17 14.0873H22V12.5964H17L16.8585 12.5914C15.83 12.5189 15.0182 11.6615 15.0182 10.6146V9.47823L15.0139 9.2783ZM8.98585 15.3594C9.0904 17.938 11.2139 19.9964 13.8182 19.9964H22V18.5055H13.8182L13.6405 18.5009C11.8755 18.4085 10.4727 16.9481 10.4727 15.16V13.7964L10.4682 13.6177C10.3752 11.7828 8.858 10.3237 7 10.3237H2V11.8146H7L7.14153 11.8196C8.17001 11.8921 8.98182 12.7495 8.98182 13.7964V15.16L8.98585 15.3594Z"
        fill="#A4A4B2"
        fillRule="evenodd"
      />
      <mask height="16" id="mask0_971_6020" maskUnits="userSpaceOnUse" width="20" x="2" y="4">
        <path
          clipRule="evenodd"
          d="M15.0139 9.2783C14.9092 6.82552 12.8876 4.86914 10.4091 4.86914H2V6.36005H10.4091L10.5802 6.36466C12.2227 6.45348 13.5273 7.81351 13.5273 9.47823V10.6146L13.5318 10.7933C13.6248 12.6282 15.142 14.0873 17 14.0873H22V12.5964H17L16.8585 12.5914C15.83 12.5189 15.0182 11.6615 15.0182 10.6146V9.47823L15.0139 9.2783ZM8.98585 15.3594C9.0904 17.938 11.2139 19.9964 13.8182 19.9964H22V18.5055H13.8182L13.6405 18.5009C11.8755 18.4085 10.4727 16.9481 10.4727 15.16V13.7964L10.4682 13.6177C10.3752 11.7828 8.858 10.3237 7 10.3237H2V11.8146H7L7.14153 11.8196C8.17001 11.8921 8.98182 12.7495 8.98182 13.7964V15.16L8.98585 15.3594Z"
          fill="white"
          fillRule="evenodd"
        />
      </mask>
      <g mask="url(#mask0_971_6020)"></g>
    </SvgIcon>
  );
}
