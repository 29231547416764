import { rest } from 'msw';

import { SUBSCRIPTION_TIER as TIER } from '../models/subscription';

const fetchSubscriptionsRes = {
  subscriptions: [
    {
      id: 'foo-1',
      tier: TIER.STARTER_MONTHLY,
      quantity: '2',
      nextBillingTime: '2024-02-02T06:05:10Z',
      remainQuantity: '1',
      status: 'active',
    },
    {
      tier: TIER.PREMIUM_MONTHLY,
      id: 'foo-2',
      quantity: '0',
      nextBillingTime: '2024-02-02T06:05:10Z',
      remainQuantity: '0',
      status: 'active',
    },
    {
      tier: TIER.PREMIUM_YEARLY,
      id: 'foo-3',
      quantity: '1',
      nextBillingTime: '2024-02-02T06:05:10Z',
      remainQuantity: '1',
      status: 'active',
    },
  ],
};

export const fetchSubscriptions = rest.get('*/v1/subscription/list', (req, res, ctx) => {
  return res(ctx.delay(2000), ctx.status(200), ctx.json(fetchSubscriptionsRes));
});

export const checkPromoCodeValidity = rest.post(
  '*/v1/promo-code/validate',
  async (req, res, ctx) => {
    const reqBody = await req.json();

    return res(ctx.delay(2000), ctx.status(200), ctx.json({ valid: reqBody?.code === '123' }));
  },
);
