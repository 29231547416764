import {
  Box,
  BoxProps,
  Button,
  Dialog,
  Link,
  Stack,
  Typography,
  useMediaQuery,
} from '@mui/material';
import { darken, styled, useTheme } from '@mui/material/styles';
import { FC, useEffect, useState } from 'react';
import { Helmet } from 'react-helmet-async';
import LoginBg from 'src/assets/backgrounds/login-bg.png';
import ConfirmationDialog from 'src/components/ConfirmationDialog';
import AltlayerLogo from 'src/components/icons/IconAltlayer.tsx';
import SuspenseLoader from 'src/components/SuspenseLoader';
import { useAuthContext } from 'src/contexts/AuthContext';

const LoginWrapper = styled(Box)(
  () => `
    display: flex;
    flex-direction: column;
    background-image: url(${LoginBg});
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center 0;
    background-attachment: fixed;
    overflow: auto;
    flex: 1;
    overflow-x: hidden;
    align-items: center;
    justify-content: space-between;
    text-align: center;

    .MuiButton-root {
      width: 240px;
      height: 40px;
      font-size: 14px;
    }
    .MuiButton-outlined {
      border-color: #9B9CF8;
      color: #9B9CF8;
    }
    .version {
      color: #A4A4B2;
    }
`,
);

const texts = ['the hassle.', 'the cost.', 'the build-time.'];

const ChangingText: FC<BoxProps> = ({ sx, ...boxProps }) => {
  const [textIndex, setTextIndex] = useState(0);
  const theme = useTheme();
  const matches = useMediaQuery(theme.breakpoints.down('sm'));
  const boxWidth = texts[textIndex]?.length * (matches ? 11.5 : 19);

  useEffect(() => {
    setInterval(() => {
      setTextIndex(state => (state + 1) % texts.length);
    }, 3000);
  }, []);

  return (
    <Box
      component="span"
      sx={{
        display: 'inline-block',
        [`@keyframes typewriter-${textIndex}`]: {
          from: { width: 0 },
          '50%': { width: `${boxWidth}px` },
          '70%': { width: `${boxWidth}px` },
          '90%': { width: 0 },
          to: { width: 0 },
        },
        '@keyframes blinkTextCursor': {
          from: { borderRightColor: '#fff' },
          to: { borderRightColor: 'transparent' },
        },
        animation: `typewriter-${textIndex} 3s steps(${
          texts[textIndex]?.length * 2
        }) 0s infinite normal both, blinkTextCursor 500ms steps(30) infinite normal`,
        overflow: 'hidden',
        whiteSpace: 'nowrap',
        borderRight: '1px solid white',
        ...sx,
      }}
      {...boxProps}
    >
      <Typography
        component="span"
        fontSize="2.5rem"
        sx={{
          display: 'inline-block',
          fontFamily: 'kanit, sans-serif',
          background:
            'linear-gradient( -90deg, #6355a4, #6355a4, #e89a3e, #e89a3e, rgb(106, 31, 252), rgb(211, 12, 199) )' /* Standard syntax (must be last) */,
          backgroundClip: 'text',
          backgroundSize: '300%',
          animation: 'animated-text 3s ease-in-out infinite',
          WebkitTextFillColor: 'transparent',
          '@keyframes animated-text': {
            '0%': {
              backgroundPositionX: '100%',
            },
            '70%': {
              backgroundPositionX: '0%',
            },
            '100%': {
              backgroundPositionX: '100%',
            },
          },
          [theme.breakpoints.down('sm')]: {
            top: '0.2rem',
            fontSize: '1.5rem',
          },
        }}
        variant="h1"
      >
        {texts[textIndex]}
      </Typography>
    </Box>
  );
};

function Login() {
  const { isLoading, login, postLoginPath } = useAuthContext();
  const [showLoginPrompt, setShowLoginPrompt] = useState(Boolean(postLoginPath));
  const theme = useTheme();

  return (
    <LoginWrapper
      sx={{
        [theme.breakpoints.down('sm')]: {
          backgroundSize: 'cover',
          backgroundPosition: 'calc(100%) 0',
        },
      }}
    >
      <Helmet>
        <title>AltLayer Rollup Launcher</title>
      </Helmet>
      <Dialog open={isLoading}>
        <SuspenseLoader fullWidth />
      </Dialog>
      <Stack direction="row" justifyContent="flex-start" mt={3} pl={6} width="100%">
        <Box>
          <AltlayerLogo />
        </Box>
      </Stack>
      <ConfirmationDialog
        handleClose={() => setShowLoginPrompt(false)}
        onConfirm={() => setShowLoginPrompt(false)}
        open={showLoginPrompt}
        title="You do not have permissions to access this page."
      >
        <Typography>
          Please login to continue to {`${window.location.origin}${postLoginPath}`}
        </Typography>
      </ConfirmationDialog>
      <Box>
        <Box>
          <Typography
            fontFamily="kanit, sans-serif"
            fontSize="4rem"
            sx={{
              [theme.breakpoints.down('sm')]: {
                fontSize: '2rem',
              },
              position: 'relative',
              overflow: 'hidden',
              '&::after': {
                content: '" "',
                transform: 'translateX(-100%)',
                borderRadius: '20rem / 1rem',
                background:
                  'linear-gradient(to right, rgba(255,255,255,0) 0%,rgba(255,255,255,0.8) 50%,rgba(128,186,232,0) 99%,rgba(125,185,232,0) 100%)',
                width: '100%',
                height: '2px',
                bottom: 0,
                left: 0,
                position: 'absolute',
                zIndex: 1,
                animation: 'slide linear 4s forwards infinite',
              },
              '@keyframes slide': {
                '0%': {
                  transform: 'translateX(-100%)',
                },
                '40%': {
                  transform: 'translateX(100%)',
                },
                '100%': {
                  transform: 'translateX(100%)',
                },
              },
            }}
            textTransform="capitalize"
            variant="h1"
          >
            AltLayer Rollup Launcher
          </Typography>
        </Box>
        <Typography
          color={darken(theme.palette.common.white, 0.05)}
          fontSize="2.5rem"
          sx={{
            [theme.breakpoints.down('sm')]: {
              fontSize: '1.5rem',
            },
            whiteSpace: 'nowrap',
            mr: 2,
            mt: 2,
            fontFamily: 'kanit, sans-serif',
          }}
          variant="body1"
        >
          Launch your own rollup
        </Typography>
        <Box
          sx={{
            position: 'relative',
            width: '100%',
            height: '4rem',
            [theme.breakpoints.down('sm')]: {
              height: '3rem',
            },
          }}
        >
          <Typography
            color={darken(theme.palette.common.white, 0.05)}
            component="span"
            fontSize="2.5rem"
            sx={{
              mt: '-0.5rem',
              whiteSpace: 'nowrap',
              fontFamily: 'kanit, sans-serif',
              transform: 'translateX(calc(-50% - 9.5rem))',
              position: 'absolute',
              left: '50%',
              [theme.breakpoints.down('sm')]: {
                fontSize: '1.5rem',
                transform: 'translateX(calc(-50% - 5.5rem))',
              },
            }}
            variant="body1"
          >
            without
          </Typography>
          <ChangingText
            sx={{
              top: '-0.1rem',
              position: 'absolute',
              left: 'calc(50% - 4.5rem)',
              textAlign: 'left',
              [theme.breakpoints.down('sm')]: {
                top: '-0.2rem',
                left: 'calc(50% - 2.5rem)',
              },
            }}
          />
        </Box>

        <Box mt={4} textAlign="center">
          <Button onClick={login} size="large" variant="contained">
            Login
          </Button>
        </Box>
      </Box>
      <Box>
        <span className="version">
          A product of{' '}
          <Link href="https://altlayer.io/" rel="noopener noreferrer" target="_blank">
            altlayer.io
          </Link>
        </span>
      </Box>
    </LoginWrapper>
  );
}

export default Login;
