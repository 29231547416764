import WarningAmberIcon from '@mui/icons-material/WarningAmber';
import { Stack, Tooltip, Typography, TypographyProps } from '@mui/material';
import { alpha, styled } from '@mui/material/styles';
import { FC } from 'react';
import { ROLES } from 'src/api/models/auth';
import { FlashLayerInfo, FlashLayerStatus } from 'src/api/models/flashlayer';
import { isDeploymentStuck } from 'src/helpers/flashlayer';

import IconActive from '../icons/IconActive';
import IconPending from '../icons/IconPending';
import IconTerminated from '../icons/IconTerminated';

export const getRoleLabel = (roleName: ROLES): JSX.Element => {
  switch (roleName) {
    default:
      return <Label color="warning">Undefined</Label>;
  }
};

const LabelWithWarning: FC<{ text: string }> = ({ text }) => {
  return (
    <Label color="warning">
      <Tooltip title="Your deployment has been in this state for more than 30 minutes. Please contact us at support@altlayer.io.">
        <Stack direction="row">
          <WarningAmberIcon
            fontSize="small"
            sx={{
              mr: 0.5,
              color: theme => theme.palette.warning.main,
              '@keyframes glow': {
                from: {
                  opacity: 0,
                },

                to: {
                  opacity: 1,
                },
              },
              animation: '1s glow',
              animationIterationCount: 'infinite',
              animationDirection: 'alternate',
            }}
          />
          {text}
        </Stack>
      </Tooltip>
    </Label>
  );
};

export const getStatusLabel = (deployment: FlashLayerInfo): JSX.Element => {
  const deploymentStatus = deployment?.status;

  switch (deploymentStatus) {
    case FlashLayerStatus.INITIALIZING: {
      if (isDeploymentStuck(deployment)) {
        return <LabelWithWarning text="Deploying" />;
      }

      return (
        <Label color="warning">
          <IconPending fontSize="small" sx={{ mr: 0.5 }} />
          Deploying
        </Label>
      );
    }

    case FlashLayerStatus.RESUMING: {
      if (isDeploymentStuck(deployment)) {
        return <LabelWithWarning text="Resuming" />;
      }

      return (
        <Label color="warning">
          <IconPending fontSize="small" sx={{ mr: 0.5 }} />
          Resuming
        </Label>
      );
    }

    case FlashLayerStatus.PENDING_FUNDS: {
      return (
        <Label color="warning">
          <IconPending fontSize="small" sx={{ mr: 0.5 }} />
          Pending Funds
        </Label>
      );
    }

    case FlashLayerStatus.IN_QUEUE: {
      if (isDeploymentStuck(deployment)) {
        return <LabelWithWarning text="Queued" />;
      }

      return (
        <Label color="pending">
          <IconPending fontSize="small" sx={{ mr: 0.5 }} />
          Queued
        </Label>
      );
    }

    case FlashLayerStatus.ACTIVE:
      return (
        <Label color="success" sx={{ borderRadius: '2rem' }}>
          <IconActive fontSize="small" sx={{ mr: 0.5 }} />
          Active
        </Label>
      );
    case FlashLayerStatus.TERMINATED:
      return (
        <Label color="error">
          <IconTerminated fontSize="small" sx={{ mr: 0.5 }} />
          Terminated
        </Label>
      );
    case FlashLayerStatus.TERMINATING:
      return (
        <Label color="error">
          <IconTerminated fontSize="small" sx={{ mr: 0.5 }} />
          Terminating
        </Label>
      );
    case FlashLayerStatus.FAILED:
      return (
        <Label color="error">
          <IconTerminated fontSize="small" sx={{ mr: 0.5 }} />
          Failed
        </Label>
      );
    case FlashLayerStatus.PUBLIC:
      return <Label color="primary">Public</Label>;
    default:
      return <Label color="error">Undefined</Label>;
  }
};

interface LabelProps {
  color?: 'primary' | 'secondary' | 'error' | 'warning' | 'success' | 'info' | 'pending';
}

const StyledTypography = styled(Typography)(
  ({ theme }) => `
      background-color: ${alpha(theme.functionalColors.containerBackground, 0.3)};
      padding: ${theme.spacing(0.5, 1)};
      font-size: ${theme.typography.pxToRem(13)};
      border-radius: 2rem;
      display: inline-flex;
      align-items: center;
      justify-content: center;
      max-height: ${theme.spacing(3)};
      font-weight: bold;
      white-space: nowrap;
      &.Label {
        &-primary {
          background-color: ${theme.palette.primary.lighter};
          color: ${theme.palette.primary.main}
        }

        &-secondary {
          background-color: ${theme.palette.secondary.lighter};
          color: ${theme.palette.secondary.main}
        }

        &-success {
          background-color: ${theme.palette.success.lighter};
          color: ${theme.palette.success.main}
        }

        &-pending {
          background-color: ${theme.palette.pending.lighter};
          color: ${theme.palette.pending.main}
        }

        &-warning {
          background-color: ${theme.palette.warning.lighter};
          color: ${theme.palette.warning.main}
        }

        &-error {
          background-color: ${theme.palette.error.lighter};
          color: ${theme.palette.error.main}
        }

        &-info {
          background-color: ${theme.palette.info.lighter};
          color: ${theme.palette.info.main}
        }
      }
`,
);

const Label: FC<LabelProps & TypographyProps> = ({ children, color = 'secondary', ...rest }) => {
  return (
    <StyledTypography className={'Label-root Label-' + color} {...rest}>
      {children}
    </StyledTypography>
  );
};

export default Label;
