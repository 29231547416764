import ChangeThemeIcon from '@mui/icons-material/LightMode';
import { IconButton, Tooltip } from '@mui/material';
import { useContext, useRef } from 'react';
import { STORAGE_KEYS } from 'src/constants';
import { ThemeContext, THEMES } from 'src/theme/ThemeProvider';

function ThemeSwitcher() {
  const ref = useRef<any>(null);

  const setThemeName = useContext(ThemeContext);

  const handleClick = (): void => {
    const curThemeName = localStorage.getItem(STORAGE_KEYS.APP_THEME);

    setThemeName(
      curThemeName === THEMES.PureLightTheme ? THEMES.FlashlyDarkTheme : THEMES.PureLightTheme,
    );
  };

  return (
    <>
      <Tooltip arrow title="Change Theme (Experimental)">
        <IconButton color="primary" onClick={handleClick} ref={ref}>
          <ChangeThemeIcon />
        </IconButton>
      </Tooltip>
    </>
  );
}

export default ThemeSwitcher;
