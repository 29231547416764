import { SvgIcon } from '@mui/material';

export default function IconExplorer(props: any) {
  return (
    <SvgIcon height="24px" viewBox="0 0 24 24" width="24px" {...props}>
      <path
        clipRule="evenodd"
        d="M19.6266 2.45924L8.43285 5.25767L8.2182 5.31739C6.74531 5.77273 5.62777 6.95154 5.25737 8.43315L2.45894 19.6269L2.43071 19.77C2.32187 20.5982 2.82809 21.3463 3.60752 21.5411C3.85889 21.604 4.12187 21.604 4.37324 21.5411L15.5669 18.7427L15.7816 18.683C17.2545 18.2277 18.372 17.0488 18.7424 15.5672L21.5408 4.37354L21.5691 4.23039C21.6779 3.40215 21.1717 2.6541 20.3923 2.45924C20.1409 2.3964 19.8779 2.3964 19.6266 2.45924ZM20.077 3.72043C20.2144 3.75478 20.3039 3.88707 20.2859 4.02548L20.2716 4.09482L17.4812 15.2519C17.2212 16.2922 16.4365 17.1199 15.4146 17.436L15.2344 17.4861L4.05795 20.28C4.01359 20.2911 3.96718 20.2911 3.92282 20.28C3.78542 20.2456 3.6959 20.1133 3.7139 19.9749L3.72816 19.9056L6.51855 8.74845C6.77861 7.7082 7.56332 6.88047 8.5852 6.56438L8.76542 6.51428L19.9418 3.72043C19.9714 3.71303 20.0019 3.71057 20.0321 3.71303L20.077 3.72043ZM13.857 9.21448C14.3699 9.21448 14.7856 9.63022 14.7856 10.1431V13.8573C14.7856 14.3702 14.3699 14.7859 13.857 14.7859H10.1428C9.62992 14.7859 9.21418 14.3702 9.21418 13.8573V10.1431C9.21418 9.63022 9.62992 9.21448 10.1428 9.21448H13.857ZM13.4856 10.5141H10.5136V13.4851H13.4856V10.5141Z"
        fill="#A4A4B2"
        fillRule="evenodd"
      />
    </SvgIcon>
  );
}
