import ArrowForwardRoundedIcon from '@mui/icons-material/ArrowForwardRounded';
import { Link, LinkProps, Typography } from '@mui/material';
import { ReactNode } from 'react';

const LinkArrow: React.FC<
  { to: string; label: ReactNode; style?: React.CSSProperties } & LinkProps
> = ({ label, sx: _sx, to, ...props }) => {
  const sx = {
    display: 'inline-flex',
    alignItems: 'center',
    fontWeight: 500,
    ..._sx,
  };

  return (
    <Link href={to} sx={sx} {...props}>
      {typeof label === 'string' ? (
        <Typography sx={{ color: 'inherit' }} variant="caption">
          {label}
        </Typography>
      ) : (
        label
      )}
      <ArrowForwardRoundedIcon
        sx={{
          height: '20px',
        }}
      />
    </Link>
  );
};

export default LinkArrow;
