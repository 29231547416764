export interface LoginResponse {
  accessToken: string;
  refreshToken: string;
}

export enum ROLES {
  ADMIN = 'admin',
  ROOT = 'root',
  ROLLUP_USER = 'rollup',
}

export interface User {
  id: string;
  name: string;
  avatar: string;
  email: string;
  address: string;
  roles: ROLES[];
}
