import { SvgIcon } from '@mui/material';

export default function IconPaidMint(props: any) {
  return (
    <SvgIcon fill="none" height="40" viewBox="0 0 40 40" width="40" {...props}>
      <path
        clipRule="evenodd"
        d="M7.71683 13.4508H1.88664L18.8769 36.7009L7.71683 13.4508ZM21.1231 36.7009L38.1133 13.4508H32.2831L21.1231 36.7009ZM29.6188 1.45078L31.3253 11.6897L21.0864 1.45078H29.6188ZM38.1002 12.5508L30.7472 2.74709L32.3812 12.5508H38.1002ZM10.3812 1.45078H18.9136L8.67472 11.6897L10.3812 1.45078ZM7.61878 12.5508H1.89975L9.25273 2.74709L7.61878 12.5508ZM20 0.550781H9.99999C9.85835 0.550781 9.72497 0.617469 9.63999 0.730781L0.639988 12.7308C0.521229 12.8891 0.519879 13.1065 0.636662 13.2663L19.6367 39.2663C19.8164 39.5123 20.1836 39.5123 20.3633 39.2663L39.3633 13.2663C39.4801 13.1065 39.4787 12.8891 39.36 12.7308L30.36 0.730781C30.275 0.617469 30.1416 0.550781 30 0.550781H20H20ZM9.08604 12.5508L20 1.63778L30.913 12.5508H9.08604ZM8.71436 13.4508H31.2846L20 36.9608L8.71436 13.4508Z"
        fill="white"
        fillRule="evenodd"
      />
    </SvgIcon>
  );
}
