import { SvgIcon } from '@mui/material';

export default function Icon(props: any) {
  return (
    <SvgIcon height="24px" viewBox="0 0 24 24" width="24px" {...props}>
      <g fill="none" fillRule="evenodd" id="页面-1" stroke="none" strokeWidth="1">
        <g id="Create3" transform="translate(-20.000000, -154.000000)">
          <g id="编组-6" transform="translate(0.000000, 64.000000)">
            <g id="intent-request--create" transform="translate(20.000000, 90.000000)">
              <polygon
                fill="#FFFFFF"
                fillRule="nonzero"
                id="路径"
                points="22.5 18 19.5 18 19.5 15 18 15 18 18 15 18 15 19.5 18 19.5 18 22.5 19.5 22.5 19.5 19.5 22.5 19.5"
              ></polygon>
              <path
                d="M11.55,22.5 L3.75,17.85 C3.3,17.55 3,17.1 3,16.575 L3,7.425 C3,6.9 3.3,6.375 3.75,6.15 L11.25,1.725 C11.475,1.575 11.7,1.5 12,1.5 C12.3,1.5 12.525,1.575 12.75,1.725 L20.25,6.15 C20.7,6.45 21,6.9 21,7.425 L21,12 L19.5,12 L19.5,7.425 L12,3 L4.5,7.425 L4.5,16.575 L12.375,21.225 L11.55,22.5 Z"
                fill="#FFFFFF"
                fillRule="nonzero"
                id="路径"
              ></path>
              <rect
                height="24"
                id="_x3C_Transparent_Rectangle_x3E__589_"
                width="24"
                x="0"
                y="0"
              ></rect>
            </g>
          </g>
        </g>
      </g>
    </SvgIcon>
  );
}
