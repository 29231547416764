import { FLASHLAYER_TIER, FlashLayerInfo, FlashLayerStatus } from 'src/api/models/flashlayer';
import { CustomChain } from 'src/types/global';

import { supportedChains } from './wagmi';

export const isResourceAvailable = (data: FlashLayerInfo) =>
  data?.status === FlashLayerStatus.ACTIVE || data?.status === FlashLayerStatus.PUBLIC;

export const appendToBasePath = (url: string, appendStr: string) => {
  if (!url) return undefined;

  const [basePath, queryParams] = url.split('?');

  return basePath + appendStr + (queryParams ? `?${queryParams}` : '');
};

const deployingStatuses = [
  FlashLayerStatus.RESUMING,
  FlashLayerStatus.INITIALIZING,
  FlashLayerStatus.IN_QUEUE,
];

export const isDeploymentStuck = (deployment: FlashLayerInfo) => {
  const dateCreated = new Date(deployment?.createdAt)?.getTime?.();

  // consider deployment stuck if > 30mins after creation and still in initializing state
  return (
    deployingStatuses.includes(deployment?.status) &&
    deployment?.tier !== FLASHLAYER_TIER.DEV &&
    Date.now() - dateCreated > 30 * 60_000
  );
};

export const shouldPollForFLStatusChanges = (deployments: FlashLayerInfo[]) => {
  // Poll if contains flash layers which are pending deployment AND not stuck
  return deployments?.some(
    cur => deployingStatuses.includes(cur.status) && !isDeploymentStuck(cur),
  );
};

export const getMockFLFromWagmiChain = (chain: CustomChain) => ({
  name: chain?.name,
  status: FlashLayerStatus.PUBLIC,
  owner: 'public',
  id: `mockfl-${chain?.id}`,
  createdAt: '2023-01-01T00:00:00.000Z',
  tier: undefined as FLASHLAYER_TIER,
  terminateAt: '',
  settings: {
    blockGasLimit: '60000000',
    blockTime: '2',
    faucet: false,
    dripAmount: '10000000000000000',
    fcfs: false,
    tokenDecimals: String(chain?.nativeCurrency?.decimals),
    tokenSymbol: chain?.nativeCurrency?.symbol,
  },
  resources: {
    rpc: chain?.rpcUrls?.default?.http?.[0],
    rpcWs: chain?.rpcUrls?.default?.webSocket?.[0],
    explorer: chain?.blockExplorers?.default?.url,
    faucet: chain?.faucetLink,
    bridge: chain?.bridgeLink,
    chainId: String(chain?.id),
  },
});

export const wagmiChainsAsMockFlashLayers: FlashLayerInfo[] = supportedChains
  ?.slice()
  ?.sort((a, b) => (a.id === 9997 ? -1 : b.id === 9997 ? 1 : a.name < b.name ? -1 : 1)) // sort multisequencer testnet first
  .map(chain => getMockFLFromWagmiChain(chain));

const flStatusSortOrder = [
  FlashLayerStatus.PUBLIC,
  FlashLayerStatus.ACTIVE,
  FlashLayerStatus.IN_QUEUE,
  FlashLayerStatus.INITIALIZING,
  FlashLayerStatus.PENDING_FUNDS,
  FlashLayerStatus.RESUMING,
  FlashLayerStatus.FAILED,
  FlashLayerStatus.TERMINATED,
  FlashLayerStatus.TERMINATING,
];

export const sortDeployments: (deployments: FlashLayerInfo[]) => FlashLayerInfo[] = (
  deployments = [],
) => {
  const cloned = deployments?.slice();

  return cloned?.sort((a, b) => {
    const statusOrder = flStatusSortOrder?.indexOf(a.status) - flStatusSortOrder?.indexOf(b.status);

    if (statusOrder === 0) {
      // sort by creation date if equal
      return a.createdAt > b.createdAt ? -1 : 1;
    }

    return statusOrder;
  });
};
