import '@mui/lab/themeAugmentation';

import { createTheme } from '@mui/material';

import { getBaseTheme, ThemeColors } from '../base';

const themeColors: ThemeColors = {
  scheme: {
    primary: '#9092FE',
    secondary: '#AFCE8C',
    success: '#9092FE',
    pending: '#AFCE8C',
    warning: '#FF9900',
    error: '#d32f2f',
    info: '#a7ffeb',
    black: '#1c1c1f',
    white: '#ffffff',
  },
  functional: {
    label: '#A4A4B2',
    appBackground: '#1c1c1f',
    iconDefault: '#AFB0CC',
    headerBackground: '#1c1c1f',
    sidebarBackground: '#111115',
    sidebarHeading: '#A4A4B2',
    defaultText: '#ffffff',
    containerBackground: '#28282F',
    cardBackground: '#3D3D49',
    textButtonBackground: '#373741',
    divider: '#4c4c58',
    disabledBackground: '#4C4C58',
    disabledText: '#DDDDE377',
  },
};

const base: any = getBaseTheme(themeColors);

const { functional: functionalColors } = themeColors;

base.palette.mode = 'dark';

base.components?.MuiButton?.variants?.push({
  props: { variant: 'contained', color: 'primary' },
  style: {
    backgroundColor: base.palette.primary.dark,
    color: functionalColors.defaultText,
  },
});
base.components.MuiButton.styleOverrides.root.fontWeight = 'bold';
base.components.MuiIconButton.styleOverrides.root.svg = {
  color: functionalColors.defaultText,
};
base.components.MuiPopover.styleOverrides.paper.boxShadow = base.palette.shadows.greaterElevation;

export const FlashlyDarkTheme = createTheme(base);
