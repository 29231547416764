import '@rainbow-me/rainbowkit/styles.css';
import './customRainbowkitStyles.css';

import AdapterDateFns from '@mui/lab/AdapterDateFns';
import LocalizationProvider from '@mui/lab/LocalizationProvider';
import { QueryClient, QueryClientProvider, QueryClientProviderProps } from '@tanstack/react-query';
import { ReactQueryDevtools } from '@tanstack/react-query-devtools';
import { AxiosError, HttpStatusCode } from 'axios';
import { Buffer } from 'buffer';
import { FC, useMemo } from 'react';
import ReactDOM from 'react-dom';
import { HelmetProvider } from 'react-helmet-async';
import { BrowserRouter } from 'react-router-dom';
import App from 'src/App';
import { SidebarProvider } from 'src/contexts/SidebarContext';
import * as serviceWorker from 'src/serviceWorker';

import { ErrorResponse } from './api/models/error';
import { ALERT_TYPE, AlertsProvider, useAlerts } from './contexts/AlertsContext';
import { AuthProvider } from './contexts/AuthContext';
import { WagmiProvider } from './contexts/WagmiContext';
import ThemeProvider from './theme/ThemeProvider';
import { ENV } from './constants';

const config = window.appConfig;

if (config.env === ENV.DEV && config?.ui?.enableMockApi) {
  // eslint-disable-next-line @typescript-eslint/no-var-requires
  const { worker } = require('./api/mocks');

  console.log(
    'Starting mock service worker. If this is the last message you see from MSW, it probably means that the service worker did not install correctly. Restart it on the dev tools applications tab and try again.',
  );
  worker.start();
}

// For web3react WalletConnect connector
window.Buffer = Buffer;

const QueryClientProviderWrapper: FC<Partial<QueryClientProviderProps>> = ({
  children,
  ...props
}) => {
  const { addAlert } = useAlerts();

  const queryClient = useMemo(
    () =>
      new QueryClient({
        defaultOptions: {
          queries: {
            /**
             * @param refetchOnWindowFocus
             * @description This is disabled temporarily to avoid confusion during development,
             * e.g. when an additional query is noticed on the network tab, a dev might think it is due to an unnecessary re-render.
             * @todo Consider enabling it in production as this could give a small UX boost.
             */
            refetchOnWindowFocus: false,
            staleTime: 60000,
            retry: false, // set to false for now, to avoid reaching postman mock server rate limit
            onError: (error: AxiosError<ErrorResponse>) => {
              const httpStatus = error?.response?.status;

              if (
                httpStatus === HttpStatusCode.TooManyRequests &&
                !error?.response?.data?.message
              ) {
                addAlert({
                  type: ALERT_TYPE.WARNING,
                  title: 'Request failed due to high network traffic.',
                  desc: 'Some features may not work as expected. Please try again in awhile.',
                });

                return;
              }

              addAlert({
                type: ALERT_TYPE.WARNING,
                title: `Request failed. ${httpStatus ? `(Code: ${httpStatus})` : ''}`,
                desc: error?.response?.data?.message,
              });
            },
          },
          mutations: {
            onError: (error: AxiosError<ErrorResponse>) => {
              const httpStatus = error?.response?.status;

              if (
                httpStatus === HttpStatusCode.TooManyRequests &&
                !error?.response?.data?.message
              ) {
                addAlert({
                  type: ALERT_TYPE.WARNING,
                  title: 'Request failed due to high network traffic.',
                  desc: 'Some features may not work as expected. Please try again in awhile.',
                });

                return;
              }

              addAlert({
                type: ALERT_TYPE.ERROR,
                title: `Request failed. ${httpStatus ? `(Code: ${httpStatus})` : ''}`,
                desc: error?.response?.data?.message,
              });
            },
          },
        },
      }),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [],
  );

  return (
    <QueryClientProvider client={queryClient} {...props}>
      {children}
    </QueryClientProvider>
  );
};

ReactDOM.render(
  <HelmetProvider>
    <ThemeProvider>
      <AlertsProvider>
        <QueryClientProviderWrapper>
          <BrowserRouter>
            <AuthProvider>
              <WagmiProvider>
                <SidebarProvider>
                  <LocalizationProvider dateAdapter={AdapterDateFns}>
                    <App />
                  </LocalizationProvider>
                </SidebarProvider>
              </WagmiProvider>
            </AuthProvider>
          </BrowserRouter>
          <ReactQueryDevtools initialIsOpen={false} />
        </QueryClientProviderWrapper>
      </AlertsProvider>
    </ThemeProvider>
  </HelmetProvider>,
  document.getElementById('root'),
);

serviceWorker.unregister();
