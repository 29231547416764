import { SvgIcon } from '@mui/material';

export default function IconTerminated(props: any) {
  return (
    <SvgIcon height="16px" viewBox="0 0 16 16" width="16px" {...props}>
      <path
        clipRule="evenodd"
        d="M8.7365 1.3045L14.6955 7.2635C14.8985 7.4665 15 7.733 15 8C15 8.267 14.8985 8.5335 14.695 8.737L8.7365 14.6955C8.5335 14.8985 8.2665 15 8 15C7.7335 15 7.4665 14.8985 7.263 14.695L1.3045 8.7365C1.1015 8.5335 1 8.267 1 8C1 7.733 1.1015 7.4665 1.3045 7.2635L7.2635 1.3045C7.4665 1.1015 7.7335 1 8 1C8.2665 1 8.5335 1.1015 8.7365 1.3045ZM1.9825 8L8 14.018L14.018 8L8 1.982L1.9825 8Z"
        fill="#F83B4C"
        fillRule="evenodd"
      />
      <rect fill="#F83B4C" height="2.16" width="5" x="5.5" y="7" />
    </SvgIcon>
  );
}
