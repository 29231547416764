import { Box, Grid, useTheme } from '@mui/material';
import { FC, useContext } from 'react';
import { Outlet, useMatch } from 'react-router-dom';
import Footer from 'src/components/Footer';
import { PATHS } from 'src/constants';
import { SidebarContext } from 'src/contexts/SidebarContext';

import Header from './Header';
import Sidebar from './Sidebar';

const ContentArea = ({ hideHeader = false, hideSidebar = false }) => {
  const { isSidebarCollapsed } = useContext(SidebarContext);
  const theme = useTheme();

  const isIDEPath = useMatch(PATHS.IDE_COMPONENT);

  return (
    <Box
      sx={{
        minHeight: '100vh',
        flex: 1,
        display: 'flex',
        flexDirection: 'column',
        transition: 'margin 0.2s',
        position: 'relative',
        zIndex: 5,
        pt: hideHeader ? 0 : `${theme.header.height}`,
        [theme.breakpoints.up('lg')]: {
          ml: `${
            hideSidebar
              ? 0
              : isSidebarCollapsed
              ? theme.sidebar.collapsedWidth
              : theme.sidebar.width
          }`,
        },
      }}
    >
      <Grid container flexDirection="column" flexGrow={1}>
        <Outlet />
      </Grid>
      {!isIDEPath && <Footer />}
    </Box>
  );
};

const NavigationLayout: FC<{ hideHeader?: boolean; hideSidebar?: boolean }> = ({
  hideHeader = false,
  hideSidebar = false,
}) => {
  return (
    <>
      <Grid
        container
        flexDirection="column"
        flexGrow={1}
        sx={{
          '.MuiPageTitle-wrapper': {
            boxShadow: 'none',
          },
        }}
      >
        {!hideHeader && <Header />}
        {!hideSidebar && <Sidebar />}
        <ContentArea hideHeader={hideHeader} hideSidebar={hideSidebar} />
      </Grid>
    </>
  );
};

export default NavigationLayout;
