import '@mui/lab/themeAugmentation';

import { createTheme } from '@mui/material';

import { getBaseTheme, ThemeColors } from '../base';

const themeColors: ThemeColors = {
  scheme: {
    primary: '#6667ab',
    secondary: '#6E759F',
    success: '#6667ab',
    pending: '#57CA22',
    warning: '#FFA319',
    error: '#FF1943',
    info: '#33C2FF',
    black: '#223354',
    white: '#ffffff',
  },
  functional: {
    label: '#7E7E8C',
    appBackground: '#f4f4f4',
    iconDefault: '#AFB0CC',
    headerBackground: '#ffffff',
    sidebarBackground: '#373741',
    sidebarHeading: '#ffffff80',
    defaultText: '#373741',
    containerBackground: '#fcfcfc',
    cardBackground: '#f4f4f4',
    textButtonBackground: '#6667AB1b',
    divider: '#A4A4B2',
    disabledBackground: '#E0E0E0',
    disabledText: '#646471',
  },
};
const base: any = getBaseTheme(themeColors);

base.palette.mode = 'light';

base.components.MuiIconButton.styleOverrides.root.svg = {
  color: base.palette.primary.main,
};

export const PureLightTheme = createTheme(base);
