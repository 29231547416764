import { SvgIcon } from '@mui/material';

export default function IconBilling(props: any) {
  return (
    <SvgIcon height="20px" viewBox="0 0 20 20" width="20px" {...props}>
      <path
        clipRule="evenodd"
        d="M4.12207 7.08203H19.8721C20.5969 7.08203 21.1846 7.66966 21.1846 8.39453V18.8945C21.1846 19.6194 20.5969 20.207 19.8721 20.207H4.12207C3.3972 20.207 2.80957 19.6194 2.80957 18.8945V5.11328C2.80957 4.38841 3.3972 3.80078 4.12207 3.80078H18.5596V5.11328H4.12207V7.08203ZM4.12207 8.39453V18.8945H19.8721V16.9258H14.6221C13.8972 16.9258 13.3096 16.3382 13.3096 15.6133V11.6758C13.3096 10.9509 13.8972 10.3633 14.6221 10.3633H19.8721V8.39453H4.12207ZM19.8721 11.6758V15.6133H14.6221V11.6758H19.8721ZM17.2471 12.9883H15.9346V14.3008H17.2471V12.9883Z"
        fill="#A4A4B2"
        fillRule="evenodd"
      />
    </SvgIcon>
  );
}
