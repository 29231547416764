import { ROLES } from 'src/api/models/auth';

/**
 *
 * @param allowedRoles undefined -> all users allowed. empty array -> all users not allowed
 * @param userRoles
 * @returns boolean
 */
export const hasAtLeastOneAllowedRole = (allowedRoles: ROLES[], userRoles: ROLES[]) =>
  allowedRoles === undefined ? true : allowedRoles?.some(cur => userRoles?.includes(cur)); // Check if user has at least one of the allowed roles
