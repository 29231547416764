import { SvgIcon } from '@mui/material';

export default function Icon(props: any) {
  return (
    <SvgIcon height="24px" viewBox="0 0 24 24" width="24px" {...props}>
      <defs>
        <path
          d="M5.57142857,0 L5.57142857,4.59642857 L8.35714286,7.38214286 C8.77005367,7.10852938 9.25466337,6.96314647 9.75,6.96428571 C11.2866342,6.96879884 12.5312012,8.21336576 12.5357143,9.75 C12.5368535,10.2453366 12.3914706,10.7299463 12.1178571,11.1428571 L14.9035714,13.9285714 L19.5,13.9285714 L19.5,19.5 L13.9285714,19.5 L13.9285714,14.9035714 L11.1428571,12.1178571 C10.7299463,12.3914706 10.2453366,12.5368535 9.75,12.5357143 C8.21336576,12.5312012 6.96879884,11.2866342 6.96428571,9.75 C6.96314647,9.25466337 7.10852938,8.77005367 7.38214286,8.35714286 L4.59642857,5.57142857 L0,5.57142857 L0,0 L5.57142857,0 Z M18.1071429,15.3214286 L15.3214286,15.3214286 L15.3214286,18.1071429 L18.1071429,18.1071429 L18.1071429,15.3214286 Z M3.48214286,6.96428571 L3.48214286,16.0178571 L12.5357143,16.0178571 L12.5357143,17.4107143 L3.48214286,17.4107143 C2.71382574,17.4084577 2.09154228,16.7861743 2.08928571,16.0178571 L2.08928571,6.96428571 L3.48214286,6.96428571 Z M16.0178571,2.08928571 C16.7861743,2.09154228 17.4084577,2.71382574 17.4107143,3.48214286 L17.4107143,12.5357143 L16.0178571,12.5357143 L16.0178571,3.48214286 L6.96428571,3.48214286 L6.96428571,2.08928571 Z M9.75,8.35714286 C8.98074624,8.35714286 8.35714286,8.98074624 8.35714286,9.75 C8.35714286,10.5192538 8.98074624,11.1428571 9.75,11.1428571 C10.5183171,11.1406006 11.1406006,10.5183171 11.1428571,9.75 C11.1428571,8.98074624 10.5192538,8.35714286 9.75,8.35714286 Z M4.17857143,1.39285714 L1.39285714,1.39285714 L1.39285714,4.17857143 L4.17857143,4.17857143 L4.17857143,1.39285714 Z"
          id="path-deployment"
        ></path>
      </defs>
      <g fill="none" fillRule="evenodd" id="页面-1" stroke="none" strokeWidth="1">
        <g id="Create3" transform="translate(-20.000000, -114.000000)">
          <g id="编组-6" transform="translate(0.000000, 64.000000)">
            <g id="Endpoint" transform="translate(20.000000, 50.000000)">
              <rect height="24" id="_Transparent_Rectangle_" width="24" x="0" y="0"></rect>
              <g id="blue" transform="translate(2.250000, 2.250000)">
                <mask fill="white" id="mask-2">
                  <use href="#path-deployment"></use>
                </mask>
                <use fill="#FFFFFF" fillRule="nonzero" href="#path-deployment" id="蒙版"></use>
              </g>
            </g>
          </g>
        </g>
      </g>
    </SvgIcon>
  );
}
