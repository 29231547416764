import { Option } from 'src/forms/FormAutocomplete';
import { Address } from 'wagmi';

export enum FLASHLAYER_TIER {
  STARTER = 'FLASHLAYER_TIER_STARTER',
  STARTER_PLUS = 'FLASHLAYER_TIER_STARTER_PLUS',
  DEV = 'FLASHLAYER_TIER_DEV',
  PREMIUM = 'FLASHLAYER_TIER_PREMIUM',
  FREE_TRIAL = 'FLASHLAYER_TIER_FREE_TRIAL',
  RESTAKING_TRIAL = 'FLASHLAYER_TIER_RESTAKING_TRIAL',
}

export enum FlashLayerStatus {
  INITIALIZING = 'STATUS_INITIALIZING',
  ACTIVE = 'STATUS_ACTIVE',
  TERMINATED = 'STATUS_TERMINATED',
  TERMINATING = 'STATUS_TERMINATING',
  FAILED = 'STATUS_FAILED',
  RESUMING = 'STATUS_RESUMING',
  PENDING_FUNDS = 'STATUS_PENDING', // For Dev Tier, waiting for user to fund
  IN_QUEUE = 'STATUS_IN_QUEUE',

  // UI ONLY
  PUBLIC = 'STATUS_PUBLIC',
}

interface TokenMetadata {
  name: string;
  symbol: string;
  decimals: string;
}

export interface AccessControlVerseProxy {
  enable?: boolean;
  methodControl?: {
    allowedMethods?: string[];
    disallowedMethods?: string[];
  };
  transactionControl?: {
    allowedFrom?: string[];
    allowedTo?: string[];
  }[];
  deployControl?: {
    allowedDeployer?: string[];
  };
}
interface NativeTokenSettings {
  l1Erc20MappedToL2Native: string;
  l1Erc20MappedToL2NativeName: string;
  l1Erc20MappedToL2NativeSymbol: string;
  l2Erc20MappedToL1NativeName: string;
  l2Erc20MappedToL1NativeSymbol: string;
}
export interface FlashLayerSetting {
  blockGasLimit: string;
  blockTime: string;
  gasless?: boolean;
  faucet: boolean;
  dripAmount?: string;
  fcfs: boolean;
  genesisAccounts?: { account: string; balance: string }[];
  tokenDecimals: string;
  tokenSymbol: string;
  explorer?: boolean;
  bridge?: boolean;
  rollup?: {
    l1ChainId: string;
    l1Endpoints: string[];
    l1Faucet: string;
    l1Explorer: string;
    l1ChainName: string;
    l1TokenMetadata: TokenMetadata;
    checkpointFixedLength?: string;
    challengePeriod?: string;
    nativeTokenMappingSetting?: NativeTokenSettings;
    l1Erc20ToNative?: string;
  };
  accessControl?: AccessControlVerseProxy;
}

interface BridgeMetadataValue {
  chainId: string;
  chainName: string;
  endpoints: string[];
  nativeToken: TokenMetadata;
}

export interface FlashLayerResource {
  rpc: string;
  rpcWs: string;
  explorer: string;
  faucet: string;
  chainId: string;
  account?: Address;
  bridge?: string;
  bridgeMetadata?: {
    l1: BridgeMetadataValue;
    l2: BridgeMetadataValue;
  };
  contractMetadata?: {
    TokenFaucet: string;
  };
}

export interface FlashLayerInfo extends FlashLayer {
  id: string;
  status: FlashLayerStatus;
  resources: FlashLayerResource;
  createdAt: string;
  owner: string;
  tier: FLASHLAYER_TIER;
  terminateAt: string;
}

export interface FlashLayer {
  name: string;
  settings: FlashLayerSetting;
}

export interface CreateFlashLayerRequest {
  flashlayer: FlashLayer;
  promoCode?: string;
  subscriptionId?: string;
  tokenId?: string;
  freeTrial?: boolean;
  restakingTrial?: boolean;
  walletSign?: {
    ts: string;
    address: string;
    sign: string;
  };
}

interface RequestWithID {
  id: string;
}
export type DestroyFlashLayerRequest = RequestWithID;
export type RestoreFlashLayerRequest = RequestWithID;
export type ResumeDeployFlashLayerRequest = RequestWithID;

export interface ListFlashLayerResult {
  flashlayers: FlashLayerInfo[];
  nextPageToken?: string;
  totalSize?: number;
}

export interface FlashLayerResult {
  flashlayer: FlashLayerInfo;
}

export interface FlashLayerFormOption extends Option {
  chainId: string;
}

export interface AddTokenToBridgeRequest {
  id: string;
  token: {
    l1Address: string;
    tokenType: string;
    tokenMetadata: TokenMetadata;
  };
}

export interface TokenData {
  l1Address: string;
  l2Address: string;
  tokenType: string;
  tokenMetadata: TokenMetadata;
}

export interface UpdateVerseProxyWhitelistReq {
  id: string;
  accessControl: AccessControlVerseProxy;
}

export interface WithdrawTokensRequest {
  id: string;
  chainId: string;
  to: Address;
  amount: string;
  sign: string;
}

export interface WithdrawTokensResult {
  txHash: `0x${string}`;
}
