import { SvgIcon } from '@mui/material';

export default function IconFlashGPT(props: any) {
  return (
    <SvgIcon fill="#AFB0CC" height="80" viewBox="0 0 80 80" width="80" {...props}>
      <path
        clipRule="evenodd"
        d="M43.5518 72.8089C43.4184 73.6696 43.9034 74.5085 44.73 74.847C44.9734 74.9429 45.233 74.9946 45.4958 74.9993C46.121 74.9757 46.6971 74.6644 47.047 74.1613L72.5736 41.7807C72.9936 41.2297 73.0761 40.5024 72.7896 39.876C72.4593 39.2219 71.7733 38.8079 71.0224 38.8093H61.6954L65.0924 23.9905C65.2241 23.4209 65.0794 22.8243 64.6997 22.3715C64.3253 21.9239 63.7622 21.6647 63.1681 21.6667H43.5322C42.6021 21.6426 41.7823 22.2549 41.5686 23.1334L35.6778 47.8949C35.5374 48.4704 35.6805 49.0764 36.065 49.5353C36.4496 49.9941 37.032 50.2537 37.6414 50.2378H47.1255L43.5518 72.8089ZM59.2015 26.9703L55.4259 43.1652H64.9583L49.5219 63.8639L53.1504 45.1006H41.5316L46.1852 26.9703H59.2015Z"
        fillRule="evenodd"
      />
      <rect height="9.16667" width="4.58333" x="7.9165" y="7.91602" />
      <rect height="9.16667" width="4.58333" x="35.4165" y="7.91602" />
      <rect height="9.16667" width="4.58333" x="45.4165" y="7.91602" />
      <rect height="18.3333" width="4.58333" x="7.9165" y="21.666" />
      <rect height="18.3333" width="4.58333" x="7.9165" y="44.584" />
      <path
        clipRule="evenodd"
        d="M21.6668 62.9173H26.2502C28.7803 62.9144 30.8306 60.8641 30.8335 58.334V49.1673C30.8306 46.6372 28.7803 44.5869 26.2502 44.584H21.6668C19.1367 44.5869 17.0864 46.6372 17.0835 49.1673V58.334C17.0864 60.8641 19.1367 62.9144 21.6668 62.9173ZM21.6668 58.334V49.1673H26.2502V58.334H21.6668Z"
        fillRule="evenodd"
      />
      <path
        clipRule="evenodd"
        d="M21.6668 39.5833H26.2502C28.7803 39.5804 30.8306 37.5301 30.8335 35V25.8333C30.8306 23.3032 28.7803 21.2529 26.2502 21.25H21.6668C19.1367 21.2529 17.0864 23.3032 17.0835 25.8333V35C17.0864 37.5301 19.1367 39.5804 21.6668 39.5833ZM21.6668 35V25.8333H26.2502V35H21.6668Z"
        fillRule="evenodd"
      />
      <path d="M26.2502 17.0827H21.6668C19.1367 17.0798 17.0864 15.0295 17.0835 12.4993V7.91602H21.6668V12.4993H26.2502V7.91602H30.8335V12.4993C30.8306 15.0295 28.7803 17.0798 26.2502 17.0827Z" />
      <path d="M62.9167 17.0827H58.3333C55.8032 17.0798 53.7529 15.0295 53.75 12.4993V7.91602H58.3333V12.4993H62.9167V7.91602H67.5V12.4993C67.4971 15.0295 65.4468 17.0798 62.9167 17.0827Z" />
    </SvgIcon>
  );
}
