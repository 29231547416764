import ChevronRightTwoToneIcon from '@mui/icons-material/ChevronRightTwoTone';
import FindInPageTwoToneIcon from '@mui/icons-material/FindInPageTwoTone';
import SearchTwoToneIcon from '@mui/icons-material/SearchTwoTone';
import {
  Avatar,
  Box,
  Button,
  Dialog,
  DialogContent,
  DialogTitle,
  Divider,
  Hidden,
  IconButton,
  InputAdornment,
  lighten,
  Link,
  List,
  ListItem,
  ListItemAvatar,
  Slide,
  TextField,
  Theme,
  Tooltip,
  Typography,
} from '@mui/material';
import { styled } from '@mui/material/styles';
import { TransitionProps } from '@mui/material/transitions';
import { ChangeEvent, forwardRef, ReactElement, Ref, useState } from 'react';

const Transition = forwardRef(function Transition(
  props: TransitionProps & { children: ReactElement<any, any> },
  ref: Ref<unknown>,
) {
  return <Slide direction="down" ref={ref} {...props} />;
});

const DialogWrapper = styled(Dialog)(
  () => `
    .MuiDialog-container {
        height: auto;
    }

    .MuiDialog-paperScrollPaper {
        max-height: calc(100vh - 64px)
    }
`,
);

const SearchInputWrapper = styled(TextField)(
  ({ theme }) => `
    background: ${theme.functionalColors.containerBackground};
    color: ${theme.functionalColors.defaultText};

    .MuiInputBase-input {
        font-size: ${theme.typography.pxToRem(17)};
    }
`,
);

const DialogTitleWrapper = styled(DialogTitle)(
  ({ theme }) => `
    background: ${theme.functionalColors.containerBackground};
    padding: ${theme.spacing(3)}
`,
);

function HeaderSearch() {
  const [openSearchResults, setOpenSearchResults] = useState(false);
  const [searchValue, setSearchValue] = useState('');

  const handleSearchChange = (event: ChangeEvent<HTMLInputElement>): void => {
    setSearchValue(event.target.value);

    if (event.target.value) {
      if (!openSearchResults) {
        setOpenSearchResults(true);
      }
    } else {
      setOpenSearchResults(false);
    }
  };

  const [open, setOpen] = useState(false);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  return (
    <>
      <Tooltip arrow title="Search">
        <IconButton color="primary" onClick={handleClickOpen}>
          <SearchTwoToneIcon />
        </IconButton>
      </Tooltip>

      <DialogWrapper
        TransitionComponent={Transition}
        fullWidth
        keepMounted
        maxWidth="md"
        onClose={handleClose}
        open={open}
        scroll="paper"
      >
        <DialogTitleWrapper>
          <SearchInputWrapper
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <SearchTwoToneIcon />
                </InputAdornment>
              ),
            }}
            autoFocus={true}
            fullWidth
            label="Search"
            onChange={handleSearchChange}
            placeholder="Search terms here..."
            value={searchValue}
          />
        </DialogTitleWrapper>
        <Divider />

        {openSearchResults && (
          <DialogContent>
            <Box display="flex" justifyContent="space-between" sx={{ pt: 0, pb: 1 }}>
              <Typography component="span" variant="body2">
                Search results for{' '}
                <Typography component="span" sx={{ fontWeight: 'bold' }} variant="body1">
                  {searchValue}
                </Typography>
              </Typography>
              <Link href="#" underline="hover" variant="body2">
                Advanced search
              </Link>
            </Box>
            <Divider sx={{ my: 1 }} />
            <List disablePadding>
              <ListItem button>
                <Hidden smDown>
                  <ListItemAvatar>
                    <Avatar
                      sx={{
                        background: (theme: Theme) => theme.palette.secondary.main,
                      }}
                    >
                      <FindInPageTwoToneIcon />
                    </Avatar>
                  </ListItemAvatar>
                </Hidden>
                <Box flex="1">
                  <Box display="flex" justifyContent="space-between">
                    <Link href="#" sx={{ fontWeight: 'bold' }} underline="hover" variant="body2">
                      Dashboard for Healthcare Platform
                    </Link>
                  </Box>
                  <Typography
                    component="span"
                    sx={{
                      color: (theme: Theme) => lighten(theme.palette.secondary.main, 0.5),
                    }}
                    variant="body2"
                  >
                    This page contains all the necessary information for managing all hospital
                    staff.
                  </Typography>
                </Box>
                <ChevronRightTwoToneIcon />
              </ListItem>
              <Divider component="li" sx={{ my: 1 }} />
              <ListItem button>
                <Hidden smDown>
                  <ListItemAvatar>
                    <Avatar
                      sx={{
                        background: (theme: Theme) => theme.palette.secondary.main,
                      }}
                    >
                      <FindInPageTwoToneIcon />
                    </Avatar>
                  </ListItemAvatar>
                </Hidden>
                <Box flex="1">
                  <Box display="flex" justifyContent="space-between">
                    <Link href="#" sx={{ fontWeight: 'bold' }} underline="hover" variant="body2">
                      Example Projects Application
                    </Link>
                  </Box>
                  <Typography
                    component="span"
                    sx={{
                      color: (theme: Theme) => lighten(theme.palette.secondary.main, 0.5),
                    }}
                    variant="body2"
                  >
                    This is yet another search result pointing to a app page.
                  </Typography>
                </Box>
                <ChevronRightTwoToneIcon />
              </ListItem>
              <Divider component="li" sx={{ my: 1 }} />
              <ListItem button>
                <Hidden smDown>
                  <ListItemAvatar>
                    <Avatar
                      sx={{
                        background: (theme: Theme) => theme.palette.secondary.main,
                      }}
                    >
                      <FindInPageTwoToneIcon />
                    </Avatar>
                  </ListItemAvatar>
                </Hidden>
                <Box flex="1">
                  <Box display="flex" justifyContent="space-between">
                    <Link href="#" sx={{ fontWeight: 'bold' }} underline="hover" variant="body2">
                      Search Results Page
                    </Link>
                  </Box>
                  <Typography
                    component="span"
                    sx={{
                      color: (theme: Theme) => lighten(theme.palette.secondary.main, 0.5),
                    }}
                    variant="body2"
                  >
                    Choose if you would like to show or not this typography section here...
                  </Typography>
                </Box>
                <ChevronRightTwoToneIcon />
              </ListItem>
            </List>
            <Divider sx={{ mt: 1, mb: 2 }} />
            <Box sx={{ textAlign: 'center' }}>
              <Button color="primary">View all search results</Button>
            </Box>
          </DialogContent>
        )}
      </DialogWrapper>
    </>
  );
}

export default HeaderSearch;
