import { useCallback, useEffect, useState } from 'react';
import { FlashLayerInfo, FlashLayerStatus } from 'src/api/models/flashlayer';
import { STORAGE_KEYS } from 'src/constants';
import { useWagmi } from 'src/contexts/WagmiContext';
import { isSameNetworkInfo } from 'src/helpers/wagmi';
import { Chain, configureChains, useSwitchNetwork } from 'wagmi';
import { publicProvider } from 'wagmi/providers/public';

interface UseAddAndSwitchNetworkArgs {
  onSwitchNetworkSuccess?: (data: Chain) => void;
}

export const useAddAndSwitchNetwork = (props?: UseAddAndSwitchNetworkArgs) => {
  const { onSwitchNetworkSuccess } = props || {};

  const { chains, setChains } = useWagmi();
  const { isLoading: isSwitching, switchNetwork } = useSwitchNetwork({
    onSuccess: onSwitchNetworkSuccess,
  });
  const [newChainId, setNewChainId] = useState<number>();

  const addAndSwitchNetwork = useCallback(
    (newNetwork: Chain) => {
      const existingWagmiChain = chains?.find(cur => cur?.id === newNetwork?.id);

      if (isSameNetworkInfo(existingWagmiChain, newNetwork)) {
        console.log('Network already exists. Switching network...');
        switchNetwork?.(newNetwork?.id);
      } else {
        console.log('Updating / Adding new network: ', newNetwork);
        const configured = configureChains(
          existingWagmiChain
            ? chains.map(cur => (cur.id === newNetwork.id ? { ...cur, ...newNetwork } : cur))
            : [...chains, newNetwork],
          [publicProvider()],
        );

        setChains(configured);
        setNewChainId(newNetwork?.id);

        const customNetworks = JSON.parse(localStorage.getItem(STORAGE_KEYS.CUSTOM_NETWORKS)) || [];

        localStorage.setItem(
          STORAGE_KEYS.CUSTOM_NETWORKS,
          JSON.stringify({ ...customNetworks, [newNetwork?.name]: newNetwork }),
        );
      }
    },
    [chains, setChains, switchNetwork],
  );

  const deleteNetwork = useCallback(
    (networkToDelete: Chain) => {
      if (!chains?.find(cur => cur?.id === networkToDelete?.id)) {
        console.error('The network is already unsupported: ', networkToDelete?.name);
      }

      const customNetworks = JSON.parse(localStorage.getItem(STORAGE_KEYS.CUSTOM_NETWORKS)) || [];

      delete customNetworks[networkToDelete.name];

      const configured = configureChains(
        chains?.filter(cur => cur?.id !== networkToDelete?.id),
        [publicProvider()],
      );

      setChains(configured);

      localStorage.setItem(STORAGE_KEYS.CUSTOM_NETWORKS, JSON.stringify(customNetworks));
    },
    [chains, setChains],
  );

  const purgeTerminatedFLNetworks = useCallback(
    (flashLayers: FlashLayerInfo[]) => {
      const terminatedFLChainIds = flashLayers
        ?.filter(cur => cur.status === FlashLayerStatus.TERMINATED)
        ?.map(cur => cur.resources?.chainId);
      const wagmiChainsToPurge = chains?.filter(cur =>
        terminatedFLChainIds?.includes(String(cur.id)),
      );

      wagmiChainsToPurge?.forEach(cur => {
        console.log('purging FL: ', cur);
        deleteNetwork(cur);
      });
    },
    [chains, deleteNetwork],
  );

  useEffect(() => {
    if (switchNetwork && newChainId && chains?.find(cur => cur?.id === newChainId)) {
      console.log('Switching to chain: ', newChainId);
      switchNetwork(newChainId);
      setNewChainId(undefined);
    }
  }, [chains, newChainId, switchNetwork]);

  return { addAndSwitchNetwork, isSwitching, chains, deleteNetwork, purgeTerminatedFLNetworks };
};
