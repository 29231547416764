import CloseTwoToneIcon from '@mui/icons-material/CloseTwoTone';
import MenuTwoToneIcon from '@mui/icons-material/MenuTwoTone';
import { Box, Button, IconButton, styled, Tooltip, useTheme } from '@mui/material';
import { ConnectButton } from '@rainbow-me/rainbowkit';
import { useContext } from 'react';
import { useNavigate } from 'react-router';
import AltlayerLogo from 'src/components/icons/IconAltlayer.tsx';
import { PATHS } from 'src/constants';
import { useAuthContext } from 'src/contexts/AuthContext';
import { SidebarContext } from 'src/contexts/SidebarContext';

import HeaderButtons from './Buttons';
import HeaderUserbox from './Userbox';

const HeaderWrapper = styled(Box)(
  ({ theme }) => `
        border-bottom: ${
          theme.palette.mode === 'dark' ? '1px solid ' + theme.functionalColors.divider : 'none'
        };
        height: ${theme.header.height};
        color: ${theme.functionalColors.defaultText};
        padding: ${theme.spacing(0, 2)};
        right: 0;
        z-index: 6;
        background-color: ${theme.header.background};
        backdrop-filter: blur(3px);
        position: fixed;
        justify-content: space-between;
        width: 100%;
        @media (min-width: ${theme.breakpoints.values.lg}px) {
            left: 0;
            width: auto;
        }
`,
);

function Header() {
  const { isSidebarCollapsed, toggleSidebar } = useContext(SidebarContext);
  const theme = useTheme();
  const { isAuthenticated } = useAuthContext();
  const navigate = useNavigate();

  return (
    <HeaderWrapper
      alignItems="center"
      display="flex"
      sx={{
        boxShadow: theme.palette.mode === 'dark' ? 'none' : theme.palette.shadows.elevated,
      }}
    >
      <Box>
        <AltlayerLogo />
      </Box>
      <Box
        alignItems="center"
        display="flex"
        sx={{
          '&': {
            justifyContent: 'flex-end',
            columnGap: { xs: 1, sm: 2 },
          },
          '&>button': {
            flex: 1,
          },
        }}
      >
        <HeaderButtons />
        <ConnectButton />
        {isAuthenticated ? (
          <HeaderUserbox />
        ) : (
          <Button onClick={() => navigate(PATHS.LOGIN)} variant="contained">
            Login
          </Button>
        )}
        <Box
          component="span"
          sx={{
            ml: 2,
            display: { lg: 'none', xs: 'inline-flex' },
            flex: 0,
          }}
        >
          <Tooltip arrow title="Toggle Menu">
            <IconButton color="primary" onClick={toggleSidebar}>
              {isSidebarCollapsed ? (
                <MenuTwoToneIcon fontSize="small" />
              ) : (
                <CloseTwoToneIcon fontSize="small" />
              )}
            </IconButton>
          </Tooltip>
        </Box>
      </Box>
    </HeaderWrapper>
  );
}

export default Header;
