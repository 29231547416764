import { SvgIcon } from '@mui/material';

export default function IconActive(props: any) {
  return (
    <SvgIcon height="16px" viewBox="0 0 16 16" width="16px" {...props}>
      <path
        clipRule="evenodd"
        d="M1 8C1 4.13401 4.13401 1 8 1C9.85652 1 11.637 1.7375 12.9497 3.05025C14.2625 4.36301 15 6.14348 15 8C15 11.866 11.866 15 8 15C4.13401 15 1 11.866 1 8ZM14 8C14 4.68629 11.3137 2 8 2C4.68629 2 2 4.68629 2 8C2 11.3137 4.68629 14 8 14C11.3137 14 14 11.3137 14 8Z"
        fillRule="evenodd"
      />
      <circle cx="8" cy="8" r="3" />
    </SvgIcon>
  );
}
