import { GoogleOAuthProvider, useGoogleLogin } from '@react-oauth/google';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import {
  createContext,
  Dispatch,
  FC,
  SetStateAction,
  useCallback,
  useContext,
  useEffect,
  useState,
} from 'react';
import { useNavigate } from 'react-router';
import {
  authUtil,
  getUserProfile,
  loginWithCode,
  loginWithRefreshToken,
  logout as _logout,
} from 'src/api/auth';
import { User } from 'src/api/models/auth';
import { PATHS } from 'src/constants';

import { ALERT_TYPE, useAlerts } from './AlertsContext';

export interface AuthContextInterface {
  login: () => void;
  isLoading: boolean;
  isInitial: boolean;
  isAuthenticated: boolean;
  user: User;
  logout: () => void;
  setPostLoginPath: Dispatch<SetStateAction<string>>;
  postLoginPath: string;
  tryLoginWithRefreshToken: () => void;
}

export const AuthContext = createContext<AuthContextInterface>({} as AuthContextInterface);

export const useAuthContext = () => {
  return useContext(AuthContext);
};

const CustAuthProvider: FC = ({ children }) => {
  const navigate = useNavigate();

  const [postLoginPath, setPostLoginPath] = useState('');
  const { addAlert } = useAlerts();

  const [isLoading, setIsLoading] = useState(false);
  const [isInitial, setIsInitial] = useState(true);

  const [isAuthenticated, setIsAuthenticated] = useState(false);
  const [user, setUser] = useState<User>();

  const onError = useCallback(
    err => {
      navigate(PATHS.LOGIN);
      setIsLoading(false);
      addAlert({
        type: ALERT_TYPE.WARNING,
        title: 'Error: could not authenticate user',
        desc: err?.message,
      });
      console.error('Error authenticating: ', err);
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [],
  );

  const loginWithAuthCode = useCallback(
    async (code: string) => {
      setIsLoading(true);
      setIsInitial(false);

      try {
        await loginWithCode(code);
        setIsAuthenticated(true);
        navigate(postLoginPath || PATHS.FLASHLAYER_DEPLOYMENTS);
        setPostLoginPath('');
        const _user = await getUserProfile();

        setUser(_user);
        setIsLoading(false);
      } catch (err) {
        onError(err);
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [onError, postLoginPath],
  );

  const googleLogin = useGoogleLogin({
    flow: 'auth-code',
    redirect_uri: 'postmessage',
    onSuccess: res => {
      loginWithAuthCode(res?.code);
    },
    onError,
  });

  const handleLogin = useCallback(() => {
    googleLogin();
  }, [googleLogin]);

  const tryLoginWithRefreshToken = useCallback(async () => {
    setIsLoading(true);
    setIsInitial(false);

    try {
      await loginWithRefreshToken();
      const _user = await getUserProfile();

      setUser(_user);
      setIsAuthenticated(true);
      setPostLoginPath('');
      setIsLoading(false);
    } catch (err) {
      onError(err);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [onError, postLoginPath]);

  const queryClient = useQueryClient();

  const { mutate: logout } = useMutation({
    mutationFn: _logout,
    onMutate: () => {
      setIsLoading(true);
    },
    onSuccess: () => {
      queryClient.clear();
      navigate(PATHS.HOME);
      setIsAuthenticated(false);
    },
    onSettled: () => {
      setIsLoading(false);
    },
  });

  useEffect(() => {
    const onLoad = async () => {
      const token = JSON.parse(window.localStorage.getItem('googleCypressToken'));

      if (token) {
        authUtil.setAccessToken(token);

        const _user = await getUserProfile();

        setUser(_user);
        setIsAuthenticated(true);
        setIsLoading(false);
        setIsInitial(false);
      }
    };

    onLoad();
  }, []);

  return (
    <AuthContext.Provider
      value={{
        login: handleLogin,
        logout,
        isAuthenticated,
        isLoading, // True when authorization is in an unknown state
        user,
        postLoginPath,
        setPostLoginPath,
        isInitial,
        tryLoginWithRefreshToken,
      }}
    >
      {children}
    </AuthContext.Provider>
  );
};

export const AuthProvider: FC = ({ children }) => (
  <GoogleOAuthProvider clientId={window.appConfig?.integrations?.googleOauthClientId}>
    <CustAuthProvider>{children}</CustAuthProvider>
  </GoogleOAuthProvider>
);
