import { rest } from 'msw';

import {
  FLASHLAYER_TIER,
  FLASHLAYER_TIER as TIER,
  FlashLayerResult,
  FlashLayerStatus,
  ListFlashLayerResult,
} from '../models/flashlayer';

export const fetchRestakingFLs = rest.get(
  '*/v1/flashlayer/restaking/trial/list',
  (req, res, ctx) => {
    return res(
      ctx.delay(2000),
      ctx.status(200),
      ctx.json({
        flashlayers: fetchFlashlayersRes?.flashlayers?.filter(
          cur => cur?.tier === FLASHLAYER_TIER.RESTAKING_TRIAL,
        ),
      }),
    );
  },
);

export const fetchFlashlayerList = rest.get('*/v1/flashlayer/list', (req, res, ctx) => {
  return res(ctx.delay(2000), ctx.status(200), ctx.json(fetchFlashlayersRes));
});
export const fetchFlashlayerInfo = rest.get('*/v1/flashlayer/info/*', (req, res, ctx) => {
  return res(ctx.delay(2000), ctx.status(200), ctx.json(fetchFlashlayerInfoRes));
});
export const createFlashlayer = rest.post('*/v1/flashlayer/create', async (req, res, ctx) => {
  const flashlayer = await req.json();

  return res(ctx.delay(2000), ctx.status(200), ctx.json({ flashlayer }));
});
export const destroyFlashlayer = rest.post('*/v1/flashlayer/destroy', async (req, res, ctx) => {
  const reqBody = await req.json();

  return res(ctx.delay(2000), ctx.status(200), ctx.json(reqBody));
});
export const restoreFlashlayer = rest.post('*/v1/flashlayer/restore', async (req, res, ctx) => {
  const reqBody = await req.json();

  return res(ctx.delay(2000), ctx.status(200), ctx.json(reqBody));
});

export const updateFlashlayerOrgQuota = rest.post(
  '*/v1/flashlayer/quota/update',
  async (req, res, ctx) => {
    const reqBody = await req.json();

    return res(ctx.delay(2000), ctx.status(200), ctx.json(reqBody));
  },
);

const fetchFlashlayerInfoRes: FlashLayerResult = {
  flashlayer: {
    name: 'egfl1',
    status: FlashLayerStatus.ACTIVE,
    settings: {
      blockTime: '2',
      faucet: true,
      fcfs: true,
      genesisAccounts: [],
      tokenDecimals: '18',
      tokenSymbol: 'TST',
      blockGasLimit: '30000000',
    },
    resources: {
      rpc: 'https://eg1-alt-producer-rpc.alt.technology',
      rpcWs: 'wss://eg1-alt-producer-rpc.alt.technology',
      explorer:
        'https://explorer.alt.technology?rpcUrl=https://eg1-alt-producer-rpc.alt.technology',
      faucet: 'https://faucet.alt.technology?chainId=1000092',
      chainId: '1000092',
      contractMetadata: {
        TokenFaucet: 'https://contract-store.alt.technology/eg1/contracts/TokenFaucet.json',
      },
    },
    createdAt: '2023-03-16T06:35:53.637713Z',
    id: '92',
    owner: '',
    tier: FLASHLAYER_TIER.STARTER,
    terminateAt: '2023-04-04T00:00:00Z',
  },
};

const fetchFlashlayersRes: ListFlashLayerResult = {
  flashlayers: [
    {
      id: '1000',
      name: 'test0',
      status: FlashLayerStatus.IN_QUEUE,
      owner: 'google-oauth2|109762320720676449085',
      settings: {
        blockGasLimit: '60000000',
        blockTime: '3',
        faucet: true,
        fcfs: false,
        genesisAccounts: [
          {
            account: '0x55085B2Fd83323d98d30d6B3342cc39de6D527f8',
            balance: '10000000000000000000000000',
          },
          {
            account: '0xcebCc534947CEce7B3275a29fc68F8AF3c5bc527',
            balance: '10000000000000000000000000',
          },
        ],
        tokenDecimals: '18',
        tokenSymbol: 'TST',
      },
      resources: {
        rpc: 'https://example2222-22222222222-22222222222222-2222222-222222222.com',
        rpcWs: 'wss://example2222222222222222222222222222222222222222222222.com',
        explorer:
          'https://explorer.alt.technology?rpcUrl=https://eg2-alt-producer-rpc.alt.technology',
        faucet: 'https://example22222222222222222222222222222222222222222.com',
        chainId: '9991',
      },
      tier: TIER.FREE_TRIAL,
      createdAt: '2022-08-31T18:00:08.247820Z',
      terminateAt: '2023-08-01T06:57:39.081Z',
    },
    {
      id: '100',
      name: 'test1',
      status: FlashLayerStatus.TERMINATED,
      owner: 'google-oauth2|109762320720676449085',
      settings: {
        blockGasLimit: '60000000',
        blockTime: '3',
        faucet: true,
        fcfs: false,
        genesisAccounts: [
          {
            account: '0x55085B2Fd83323d98d30d6B3342cc39de6D527f8',
            balance: '10000000000000000000000000',
          },
          {
            account: '0xcebCc534947CEce7B3275a29fc68F8AF3c5bc527',
            balance: '10000000000000000000000000',
          },
        ],
        tokenDecimals: '18',
        tokenSymbol: 'TST',
      },
      resources: {
        rpc: 'https://example2222-22222222222-22222222222222-2222222-222222222.com',
        rpcWs: 'wss://example2222222222222222222222222222222222222222222222.com',
        explorer:
          'https://explorer.alt.technology?rpcUrl=https://eg2-alt-producer-rpc.alt.technology',
        faucet: 'https://example22222222222222222222222222222222222222222.com',
        chainId: '9991',
      },
      tier: TIER.PREMIUM,
      createdAt: '2022-08-31T18:00:08.247820Z',
      terminateAt: '2023-04-01T06:57:39.081Z',
    },
    {
      id: '101',
      name: 'test2',
      status: FlashLayerStatus.ACTIVE,
      owner: 'e998ee7c-02a9-4d43-94ed-7d3f096c0d9f',
      settings: {
        blockGasLimit: '60000000',
        blockTime: '3',
        faucet: true,
        fcfs: true,
        genesisAccounts: [
          {
            account: '0x55085B2Fd83323d98d30d6B3342cc39de6D527f8',
            balance: '10000000000000000000000000',
          },
          {
            account: '0xcebCc534947CEce7B3275a29fc68F8AF3c5bc527',
            balance: '10000000000000000000000000',
          },
        ],
        tokenDecimals: '100',
        tokenSymbol: 'TST',
      },
      resources: {
        rpc: 'https://example11111111111111111111111111111111111111111.com',
        rpcWs: 'wss://example11111111111111111111111111111111111111111.com',
        explorer:
          'https://explorer.alt.technology?rpcUrl=https://eg11111-alt-producer-rpc.alt.technology',
        faucet: 'https://example111111111111111111111111111111111111111.com',
        chainId: '9992',
      },
      tier: '' as TIER,
      createdAt: '2022-08-31T18:00:08.247820Z',
      terminateAt: '2023-04-01T06:57:39.081Z',
    },
    {
      id: '102',
      name: 'test3',
      status: FlashLayerStatus.INITIALIZING,
      owner: 'google-oauth2|109762320720676449085',
      settings: {
        blockGasLimit: '60000000',
        blockTime: '3',
        faucet: true,
        fcfs: false,
        genesisAccounts: [
          {
            account: '0x55085B2Fd83323d98d30d6B3342cc39de6D527f8',
            balance: '10000000000000000000000000',
          },
          {
            account: '0xcebCc534947CEce7B3275a29fc68F8AF3c5bc527',
            balance: '10000000000000000000000000',
          },
        ],
        tokenDecimals: '100',
        tokenSymbol: 'TST',
      },
      resources: {
        rpc: 'https://www.example.com',
        rpcWs: 'wss://www.example.com',
        explorer: '',
        faucet: '',
        chainId: '9993',
      },
      tier: TIER.STARTER,
      createdAt: '2023-02-01T06:57:39.081Z',
      terminateAt: '',
    },
    {
      id: '104',
      name: 'test4',
      status: FlashLayerStatus.PENDING_FUNDS,
      owner: 'google-oauth2|109762320720676449085',
      settings: {
        blockGasLimit: '60000000',
        blockTime: '3',
        faucet: false,
        fcfs: false,
        rollup: {
          l1ChainId: '11155111',
          l1Endpoints: ['https://rpc.sepolia.org'],
          l1Faucet: 'https://sepoliafaucet.com',
          l1Explorer: 'https://sepolia.etherscan.io',
          l1ChainName: 'Sepolia',
          l1TokenMetadata: {
            name: 'Sepolia ETH',
            symbol: 'SETH',
            decimals: '18',
          },
          checkpointFixedLength: '0',
          challengePeriod: '0',
        },
        tokenDecimals: '100',
        tokenSymbol: 'TST',
      },
      resources: {
        rpc: 'https://www.example.com',
        rpcWs: 'wss://www.example.com',
        explorer:
          'https://explorer.alt.technology?rpcUrl=https://eg11111-alt-producer-rpc.alt.technology',
        faucet: 'https://example22222222222222222222222222222222222222222.com',
        chainId: '9994',
        account: '0x32de807a8691bD3F3002c64885F028c6075BAF46',
        bridge: 'https://example.com',
        bridgeMetadata: {
          l1: {
            chainId: '11155111',
            chainName: 'Sepolia',
            endpoints: ['https://rpc.sepolia.org'],
            nativeToken: {
              name: 'ETH',
              symbol: 'ETH',
              decimals: '18',
            },
          },
          l2: {
            chainId: '9993',
            chainName: 'Alt Beacon Chain',
            endpoints: ['https://devtier-ganache.alt.technology'],
            nativeToken: {
              name: 'ALT',
              symbol: 'ALT',
              decimals: '18',
            },
          },
        },
      },
      tier: TIER.DEV,
      createdAt: '2023-02-01T06:57:39.081Z',
      terminateAt: '',
    },
    {
      id: '105',
      name: 'test5restaking',
      status: FlashLayerStatus.ACTIVE,
      owner: '0x42D631e3c14C46Ae6944D4ad8aB03835346dF41F',
      settings: {
        blockGasLimit: '60000000',
        blockTime: '3',
        faucet: true,
        fcfs: false,
        genesisAccounts: [
          {
            account: '0x55085B2Fd83323d98d30d6B3342cc39de6D527f8',
            balance: '10000000000000000000000000',
          },
          {
            account: '0xcebCc534947CEce7B3275a29fc68F8AF3c5bc527',
            balance: '10000000000000000000000000',
          },
        ],
        tokenDecimals: '18',
        tokenSymbol: 'TST',
      },
      resources: {
        rpc: 'https://example2222-22222222222-22222222222222-2222222-222222222.com',
        rpcWs: 'wss://example2222222222222222222222222222222222222222222222.com',
        explorer:
          'https://explorer.alt.technology?rpcUrl=https://eg2-alt-producer-rpc.alt.technology',
        faucet: 'https://example22222222222222222222222222222222222222222.com',
        chainId: '9991',
      },
      tier: TIER.RESTAKING_TRIAL,
      createdAt: '2022-08-31T18:00:08.247820Z',
      terminateAt: '2023-08-01T06:57:39.081Z',
    },
    {
      id: '106',
      name: 'test6restaking',
      status: FlashLayerStatus.INITIALIZING,
      owner: '0x42D631e3c14C46Ae6944D4ad8aB03835346dF41F',
      settings: {
        blockGasLimit: '60000000',
        blockTime: '3',
        faucet: true,
        fcfs: false,
        genesisAccounts: [
          {
            account: '0x55085B2Fd83323d98d30d6B3342cc39de6D527f8',
            balance: '10000000000000000000000000',
          },
          {
            account: '0xcebCc534947CEce7B3275a29fc68F8AF3c5bc527',
            balance: '10000000000000000000000000',
          },
        ],
        tokenDecimals: '18',
        tokenSymbol: 'TST',
      },
      resources: {
        rpc: 'https://example2222-22222222222-22222222222222-2222222-222222222.com',
        rpcWs: 'wss://example2222222222222222222222222222222222222222222222.com',
        explorer:
          'https://explorer.alt.technology?rpcUrl=https://eg2-alt-producer-rpc.alt.technology',
        faucet: 'https://example22222222222222222222222222222222222222222.com',
        chainId: '9991',
      },
      tier: TIER.RESTAKING_TRIAL,
      createdAt: '2022-07-07T18:00:08.247820Z',
      terminateAt: '2023-07-15T06:57:39.081Z',
    },
  ],
};
