import axios, { AxiosRequestHeaders } from 'axios';

import { authUtil } from './auth';

const config = window.appConfig;

export const instance = axios.create({
  baseURL: config?.integrations?.dashboardSvcUrl,
});

instance.interceptors.request.use(config => {
  config.headers = { ...authUtil.getAuthHeaders(), ...config.headers } as AxiosRequestHeaders;

  return config;
});
