import CheckCircleOutlinedIcon from '@mui/icons-material/CheckCircleOutlined';
import CloseIcon from '@mui/icons-material/Close';
import ErrorOutlineOutlinedIcon from '@mui/icons-material/ErrorOutlineOutlined';
import HighlightOffIcon from '@mui/icons-material/HighlightOff';
import PendingIcon from '@mui/icons-material/PendingOutlined';
import { alpha, Box, Grid, Paper, PaperProps, Theme, Typography, useTheme } from '@mui/material';

import Duration from './Duration';
import LinkArrow from './LinkArrow';
import { ALERT_TYPE } from '.';

export const getAlertColor = (type: ALERT_TYPE, theme: Theme) => {
  switch (type) {
    case ALERT_TYPE.SUCCESS:
      return theme.palette.success.main;
    case ALERT_TYPE.PENDING:
      return theme.palette.pending.main;
    case ALERT_TYPE.INFO:
    case ALERT_TYPE.DEBUG:
      return theme.palette.info.main;
    case ALERT_TYPE.WARNING:
      return theme.palette.warning.main;
    case ALERT_TYPE.ERROR:
      return theme.palette.error.main;
    default:
      return 'transparent';
  }
};

const Toast: React.FC<
  {
    duration?: number;
    onClose?: () => void;
    type: ALERT_TYPE;
    title: string;
    link?: {
      url: string;
      label: string;
    };
    desc?: string;
  } & PaperProps
> = ({ desc, duration = 5000, link, onClose, title, type, ...props }) => {
  const theme = useTheme();
  const alertColor = getAlertColor(type, theme);

  return (
    <Paper
      sx={{
        position: 'relative',
        width: '400px',
        padding: 2.5,
        background: theme.functionalColors.containerBackground,
        boxShadow: '0px 4px 16px rgba(0, 0, 0, 0.12)',
        borderRadius: 1,
        overflow: 'hidden',
      }}
      {...props}
    >
      <Grid container>
        {type === ALERT_TYPE.SUCCESS ? (
          <CheckCircleOutlinedIcon sx={{ color: alertColor }} />
        ) : type === ALERT_TYPE.PENDING ? (
          <PendingIcon sx={{ color: alertColor }} />
        ) : type === ALERT_TYPE.ERROR ? (
          <HighlightOffIcon sx={{ color: alertColor }} />
        ) : (
          <ErrorOutlineOutlinedIcon sx={{ color: alertColor }} />
        )}
        <Box flex={1} marginLeft={1.25}>
          <Grid alignItems="center" container justifyContent="space-between">
            <Typography
              sx={{
                paddingRight: '26px',
                wordBreak: 'break-all',
                color: alertColor,
              }}
            >
              {title}
            </Typography>
            <CloseIcon
              onClick={onClose}
              sx={{
                right: '22px',
                top: '22px',
                position: 'absolute',
                cursor: 'pointer',
                fill: alertColor,
                height: '19px',
                width: '19px',
              }}
            />
          </Grid>
          {link?.url && (
            <LinkArrow
              label={link.label}
              rel="noopener noreferrer"
              sx={{
                marginTop: '10px',
                color: alpha(alertColor, 0.8),
                textDecorationColor: alpha(alertColor, 0.8),
                '& svg path': { fill: alpha(alertColor, 0.8) },
              }}
              target="_blank"
              to={link.url}
            />
          )}
          {desc && (
            <Typography
              sx={{
                marginTop: '16px',
                color: theme.palette.common.white,
                wordBreak: 'break-all',
                maxHeight: '150px',
                textOverflow: 'ellipsis',
                paddingRight: '12px',
                scrollbarColor: 'rebeccapurple green',
                scrollbarWidth: 'thin',
                overflowY: 'auto',
              }}
              variant="body1"
            >
              {desc}
            </Typography>
          )}
        </Box>
      </Grid>
      <Box bottom="2px" left="0px" position="absolute" right="0px">
        <Duration delay={duration / 1000} type={type} />
      </Box>
    </Paper>
  );
};

export default Toast;
