import { MutationFunction } from '@tanstack/react-query';
import jwt from 'jwt-decode';

import { LoginResponse, User } from './models/auth';
import { instance } from './index';

export const authUtil = (() => {
  let _accessToken: string;
  let _headers = {};

  const setAccessToken = (val: string) => {
    _accessToken = val;
    _headers = {
      Authorization: `Bearer ${_accessToken}`,
    };
  };

  const getAccessToken = () => _accessToken;
  const getAuthHeaders = () => _headers;

  const getRoles = () => {
    const decoded: any = jwt(_accessToken);

    return decoded?.roles;
  };

  return { getAccessToken, setAccessToken, getAuthHeaders, getRoles };
})();

const renewAccessTokenLater = (accessToken: string) => {
  const decoded: any = jwt(accessToken);
  const msTillExp = decoded?.exp * 1000 - Date.now();

  setTimeout(() => {
    loginWithRefreshToken();
    // call refresh endpoint 1min before access token expires
  }, msTillExp - 60000);
};

export const loginWithRefreshToken: MutationFunction<LoginResponse, void> = async () => {
  const res = await instance.post('/login/refresh', {}, { withCredentials: true }); // sends cookies to backend and allows backend to set new refresh token

  authUtil.setAccessToken(res?.data?.accessToken);
  renewAccessTokenLater(res?.data?.accessToken);

  return res?.data;
};

export const loginWithCode: MutationFunction<LoginResponse, string> = async (code: string) => {
  const res = await instance.post('/login', { google: { code } }, { withCredentials: true }); // allow setting of cookies by backend

  authUtil.setAccessToken(res?.data?.accessToken);
  renewAccessTokenLater(res?.data?.accessToken);

  return res?.data;
};

export const logout: MutationFunction<Record<string, never>, void> = async () => {
  const res = await instance.post('/logout', {});

  return res?.data;
};

export const getUserProfile: () => Promise<User> = async () => {
  const res = await instance.get('/profile');

  return res?.data;
};
