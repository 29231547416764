import { Button, Stack } from '@mui/material';
import React from 'react';
import { useNavigate } from 'react-router';
import { PATHS } from 'src/constants';

const HomeButton = ({ setState }) => {
  const navigate = useNavigate();

  return (
    <Button
      onClick={() => {
        setState({ hasError: false });
        navigate(PATHS.HOME);
      }}
    >
      To Home Page
    </Button>
  );
};

const BackButton = ({ setState }) => {
  const navigate = useNavigate();

  return (
    <Button
      onClick={() => {
        setState({ hasError: false });
        navigate(-1);
      }}
    >
      Go Back
    </Button>
  );
};

class ErrorBoundary extends React.Component {
  constructor(props) {
    super(props);
    this.state = { hasError: false };
  }

  static getDerivedStateFromError(error) {
    // Update state so the next render will show the fallback UI.
    console.error('error: ', error);

    return { hasError: true };
  }

  render() {
    if (this.state.hasError) {
      // You can render any custom fallback UI
      return (
        <Stack alignItems="center" justifyContent="center" sx={{ height: '100vh' }}>
          Sorry, something went wrong.
          <Stack direction="row" mt={2} spacing={2}>
            <HomeButton setState={this.setState.bind(this)} />
            <BackButton setState={this.setState.bind(this)} />
          </Stack>
        </Stack>
      );
    }

    return this.props.children;
  }
}

export default ErrorBoundary;
