import { SvgIcon } from '@mui/material';

export default function IconManageFlashLayer(props: any) {
  return (
    <SvgIcon height="24px" viewBox="0 0 24 24" width="24px" {...props}>
      <path
        clipRule="evenodd"
        d="M3.77539 3.75H20.2754C21.0344 3.75087 21.6495 4.36597 21.6504 5.125V11.3125H20.2754V9.25H3.77539V18.875H10.6504V20.25H3.77539C3.01636 20.2491 2.40126 19.634 2.40039 18.875V5.125C2.40115 4.36592 3.01631 3.75076 3.77539 3.75ZM3.77539 7.875H20.2754V5.125H3.77539V7.875ZM20.2059 16.125H21.6504V17.5H20.2059C20.1172 17.9304 19.9463 18.3396 19.7026 18.7052L20.727 19.7296L19.7549 20.7017L18.7305 19.6773C18.3649 19.921 17.9557 20.0918 17.5254 20.1805V21.625H16.1504V20.1805C15.72 20.0918 15.3108 19.921 14.9452 19.6772L13.9208 20.7016L12.9487 19.7295L13.9731 18.7051C13.7294 18.3395 13.5585 17.9303 13.4699 17.5H12.0254V16.125H13.4699C13.5586 15.6946 13.7294 15.2854 13.9731 14.9198L12.9488 13.8954L13.9209 12.9233L14.9453 13.9477C15.3109 13.704 15.7201 13.5332 16.1504 13.4445V12H17.5254V13.4445C17.9558 13.5332 18.365 13.704 18.7306 13.9478L19.755 12.9234L20.7271 13.8955L19.7027 14.9199C19.9464 15.2855 20.1172 15.6947 20.2059 16.125ZM14.7754 16.8125C14.7754 17.9516 15.6988 18.875 16.8379 18.875C17.9765 18.8737 18.8991 17.9511 18.9004 16.8125C18.9004 15.6734 17.977 14.75 16.8379 14.75C15.6988 14.75 14.7754 15.6734 14.7754 16.8125ZM14.7754 7.1875C15.1551 7.1875 15.4629 6.8797 15.4629 6.5C15.4629 6.1203 15.1551 5.8125 14.7754 5.8125C14.3957 5.8125 14.0879 6.1203 14.0879 6.5C14.0879 6.8797 14.3957 7.1875 14.7754 7.1875ZM17.5254 6.5C17.5254 6.8797 17.2176 7.1875 16.8379 7.1875C16.4582 7.1875 16.1504 6.8797 16.1504 6.5C16.1504 6.1203 16.4582 5.8125 16.8379 5.8125C17.2176 5.8125 17.5254 6.1203 17.5254 6.5ZM18.9004 7.1875C19.2801 7.1875 19.5879 6.8797 19.5879 6.5C19.5879 6.1203 19.2801 5.8125 18.9004 5.8125C18.5207 5.8125 18.2129 6.1203 18.2129 6.5C18.2129 6.8797 18.5207 7.1875 18.9004 7.1875Z"
        fill="#A4A4B2"
        fillRule="evenodd"
      />
    </SvgIcon>
  );
}
