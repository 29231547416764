import { ThemeProvider } from '@mui/material';
import { StylesProvider } from '@mui/styles';
import React, { useState } from 'react';
import { STORAGE_KEYS } from 'src/constants';

import { themeCreator } from './base';

export enum THEMES {
  PureLightTheme = 'PureLightTheme',
  FlashlyDarkTheme = 'FlashlyDarkTheme',
}

type ThemeValue = (themeName: string) => void;
export const ThemeContext = React.createContext((() => {}) as ThemeValue);

const ThemeProviderWrapper: React.FC = props => {
  const curThemeName = localStorage.getItem(STORAGE_KEYS.APP_THEME) || THEMES.FlashlyDarkTheme;
  const [themeName, _setThemeName] = useState(curThemeName);
  const theme = themeCreator(themeName);

  const setThemeName = (themeName: THEMES): void => {
    localStorage.setItem(STORAGE_KEYS.APP_THEME, themeName);
    _setThemeName(themeName);
  };

  return (
    <StylesProvider injectFirst>
      <ThemeContext.Provider value={setThemeName}>
        <ThemeProvider theme={theme}>{props.children}</ThemeProvider>
      </ThemeContext.Provider>
    </StylesProvider>
  );
};

export default ThemeProviderWrapper;
