import { setupWorker } from 'msw';

import * as flashlayerApi from './flashlayer';
import * as openaiMocks from './openai';
import * as rpcMocks from './rpc';
import * as subscriptionApi from './subscription';

const handlers = [
  ...Object.values(flashlayerApi),
  ...Object.values(subscriptionApi),
  ...Object.values(openaiMocks),
  ...Object.values(rpcMocks),
];

export const worker = setupWorker(...handlers);
