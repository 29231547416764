import { styled, SxProps, useMediaQuery, useTheme } from '@mui/material';
import { FC } from 'react';
import { Link } from 'react-router-dom';
import { PATHS } from 'src/constants';

import IconAltlayerLong from './IconAltlayerLong';
import IconAltlayerSmall from './IconAltlayerSmall';

const AltlayerLogoWrapper = styled(Link)(
  ({ theme }) => `
        color: ${theme.palette.text.primary};
        display: flex;
        text-decoration: none;
        margin: 0 auto;
        font-weight: ${theme.typography.fontWeightBold};
`,
);

interface IAltLayerLogo {
  size?: 'small' | 'medium' | 'big';
  sx?: SxProps;
}

const AltlayerLogo: FC<IAltLayerLogo> = ({ size = 'small', sx, ...rest }) => {
  const theme = useTheme();
  const sm = useMediaQuery(theme.breakpoints.up('sm'));

  const dimensions = {
    small: [32, 187],
    medium: [40, 240],
    big: [60, 346],
  };

  return (
    <AltlayerLogoWrapper
      sx={{ color: 'primary.light', width: { sm: size === 'small' ? 187 : 346, xs: 0 }, ...sx }}
      to={PATHS.HOME}
      {...rest}
    >
      {sm ? (
        <IconAltlayerLong height={dimensions[size]?.[0]} width={dimensions[size]?.[1]} />
      ) : (
        <IconAltlayerSmall />
      )}
    </AltlayerLogoWrapper>
  );
};

export default AltlayerLogo;
