import { Box, BoxProps, useTheme } from '@mui/material';
import { useEffect, useState } from 'react';

import { getAlertColor } from './Toast';
import { ALERT_TYPE } from '.';

const Duration: React.FC<{ delay?: number; type: ALERT_TYPE } & BoxProps> = ({
  delay = 2,
  type,
  ...props
}) => {
  const theme = useTheme();
  const color = getAlertColor(type, theme);

  const [width, setWidth] = useState(100);

  useEffect(() => {
    // without setTimeout, somehow it causes css transitions to fail when there are many alerts
    setTimeout(() => setWidth(0));
  }, []);

  return (
    <Box
      sx={{
        background: color,
        height: '2px',
        width: `${width}%`,
        transition: `width ${delay}s ease-out`,
      }}
      {...props}
    />
  );
};

export default Duration;
