import CloseIcon from '@mui/icons-material/Close';
import { LoadingButton } from '@mui/lab';
import {
  alpha,
  Button,
  ButtonProps,
  Dialog,
  DialogContent,
  DialogProps,
  DialogTitle,
  Stack,
  Typography,
  useTheme,
} from '@mui/material';
import { FC, ReactNode, useCallback, useMemo } from 'react';

interface ConfirmationDialogProps {
  open: boolean;
  title?: ReactNode;
  handleClose: () => void;
  onCancel?: () => void;
  onConfirm: () => void;
  isLoading?: boolean;
  confirmButtonText?: ReactNode;
  confirmButtonProps?: ButtonProps;
  cancelButtonProps?: ButtonProps;
  cancelButtonText?: ReactNode;
  renderButtons?: (defaultButtons: JSX.Element[]) => JSX.Element[];
}

const ConfirmationDialog: FC<ConfirmationDialogProps & Omit<DialogProps, 'title'>> = ({
  cancelButtonProps,
  cancelButtonText = 'Cancel',
  children,
  confirmButtonProps,
  confirmButtonText = 'Confirm',
  handleClose,
  isLoading = false,
  onCancel,
  onConfirm,
  open = false,
  renderButtons = defaultButtons => defaultButtons,
  sx,
  title = 'Are you sure you want to proceed?',
  ...rest
}) => {
  const handleConfirm = useCallback(() => {
    onConfirm();
  }, [onConfirm]);

  const theme = useTheme();

  const defaultButtons: JSX.Element[] = useMemo(
    () => [
      <Button
        autoFocus
        className="cancel-button"
        key="cancel"
        onClick={() => {
          if (onCancel) {
            onCancel();
          } else {
            handleClose();
          }
        }}
        size="small"
        variant="outlined"
        {...cancelButtonProps}
      >
        {cancelButtonText}
      </Button>,
      <LoadingButton
        className="confirm-button"
        key="confirm"
        loading={isLoading}
        onClick={handleConfirm}
        size="small"
        variant="contained"
        {...confirmButtonProps}
      >
        {confirmButtonText}
      </LoadingButton>,
    ],
    [
      cancelButtonProps,
      cancelButtonText,
      confirmButtonProps,
      confirmButtonText,
      handleClose,
      handleConfirm,
      isLoading,
      onCancel,
    ],
  );

  return (
    <Dialog
      onClose={handleClose}
      open={open}
      sx={{
        '& .MuiDialog-paper': {
          background: theme.functionalColors.containerBackground,
          borderRadius: 0.5,
          boxShadow: theme.palette.shadows.mildElevation,
        },
        ...sx,
      }}
      {...rest}
    >
      <DialogTitle>
        <Stack alignItems="center" direction="row" justifyContent="space-between" spacing={2}>
          <Typography color="primary" variant="h4">
            {title}
          </Typography>
          <CloseIcon
            onClick={handleClose}
            sx={{ cursor: 'pointer', color: alpha(theme.palette.primary.main, 0.5) }}
          />
        </Stack>
      </DialogTitle>

      <DialogContent>
        {children}
        <Stack
          direction="row"
          justifyContent="flex-end"
          mt={3}
          spacing={1}
          sx={{ '& button': { minWidth: 80 } }}
        >
          {renderButtons(defaultButtons)}
        </Stack>
      </DialogContent>
    </Dialog>
  );
};

export default ConfirmationDialog;
