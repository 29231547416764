import { SvgIcon } from '@mui/material';

export default function IconGuide(props: any) {
  return (
    <SvgIcon height="24px" viewBox="0 0 24 24" width="24px" {...props}>
      <path
        clipRule="evenodd"
        d="M3.85714 4.59961H20.1429C20.8921 4.60036 21.4993 5.20753 21.5 5.95675V18.171C21.4993 18.9203 20.8921 19.5274 20.1429 19.5282H3.85714C3.10792 19.5274 2.50075 18.9203 2.5 18.171V5.95675C2.50075 5.20753 3.10792 4.60036 3.85714 4.59961ZM11.3214 5.95675H3.85714V18.171H11.3214V5.95675ZM12.6786 18.171V5.95675H20.1429V18.171H12.6786ZM5.21429 7.99247H9.96429V9.34961H5.21429V7.99247ZM14.0357 7.99247H18.7857V9.34961H14.0357V7.99247ZM14.0357 11.3853H18.7857V12.7425H14.0357V11.3853ZM18.7857 14.7782H14.0357V16.1353H18.7857V14.7782ZM9.96429 11.3853H5.21429V12.7425H9.96429V11.3853ZM5.21429 14.7782H9.96429V16.1353H5.21429V14.7782Z"
        fill="#A4A4B2"
        fillRule="evenodd"
      />
    </SvgIcon>
  );
}
