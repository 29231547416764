import * as _allWagmiChains from '@wagmi/chains';
import { FlashLayerInfo } from 'src/api/models/flashlayer';
import { STORAGE_KEYS } from 'src/constants';
import { CustomChain } from 'src/types/global';

const wagmiChainOverrides = window.appConfig?.defaultWagmiChains as Record<string, CustomChain>;

const allWagmiChains = Object.keys(_allWagmiChains)?.reduce(
  (acc, key) => ({
    ...acc,
    [key]: {
      ...(_allWagmiChains as Record<string, _allWagmiChains.Chain>)[key],
      ...wagmiChainOverrides[key],
    },
  }),
  {} as Record<string, CustomChain>,
);

const customChains = window.appConfig?.customChains || {};

const userAddedChains: Record<string, CustomChain> =
  JSON.parse(localStorage.getItem(STORAGE_KEYS.CUSTOM_NETWORKS)) || {};

// Sources for chain information, in order of preference:
// 1. userAddedChains - Local storage (Partial info, e.g. does not include iconUrl)
// 2. customChains - appConfig.js custom networks (Complete info),
// 3. allWagmiChains - wagmi core chains with supplementary info from appConfig.js (Complete info)
const chainInfoSources = [allWagmiChains, customChains, userAddedChains] as Record<
  string,
  CustomChain
>[];

// Chains whose RPC info is available to the app, but may or may not be supported
export const allChainsKnownToApp = chainInfoSources.reduce((acc, curData) => {
  const currentChainsWithKeysToOverwrite = Object.keys(curData)?.reduce((curAcc, curKey) => {
    const chainInfo = curData[curKey];
    const existingKey = Object.keys(acc)?.find(accKey => acc[accKey]?.id === chainInfo?.id);

    if (existingKey) {
      // override existing chainInfo with combined chainInfo
      return {
        ...curAcc,
        [existingKey]: {
          ...acc[existingKey],
          ...chainInfo,
        },
      };
    }

    return { ...curAcc, [curKey]: chainInfo };
  }, {});

  return {
    ...acc,
    ...currentChainsWithKeysToOverwrite,
  };
}, {} as Record<string, CustomChain>);

export const chainIdToChainInfoMap: Record<string, CustomChain> = Object.values(
  allChainsKnownToApp,
)?.reduce((acc, cur) => ({ ...acc, [String(cur.id)]: cur }), {});

const chainsToSupport = Object.keys({
  ...wagmiChainOverrides,
  ...customChains,
  ...userAddedChains,
});

const unsortedSupportedChains = Object.keys(allChainsKnownToApp)?.reduce((acc, key) => {
  return chainsToSupport.includes(key) ? [...acc, allChainsKnownToApp[key]] : acc;
}, [] as CustomChain[]);

export const supportedChains: ReadonlyArray<CustomChain> = unsortedSupportedChains?.sort((a, b) => {
  let aliasA = a.name;
  let aliasB = b.name;

  // Workaround to sort opBNB together with BNB

  if ([204, 5611]?.includes(a.id)) {
    aliasA = 'Binance2';
  }

  if ([204, 5611]?.includes(b.id)) {
    aliasB = 'Binance2';
  }

  return aliasA > aliasB ? 1 : -1;
});

export const getWagmiChainFromFL = (data: FlashLayerInfo): CustomChain => ({
  id: parseInt(data?.resources?.chainId),
  name: data?.name,
  network: data?.name,
  nativeCurrency: {
    name: data?.settings?.tokenSymbol,
    symbol: data?.settings?.tokenSymbol,
    decimals: parseInt(data?.settings?.tokenDecimals),
  },
  rpcUrls: {
    default: {
      http: [data?.resources?.rpc],
    },
    public: {
      http: [data?.resources?.rpc],
    },
  },
  blockExplorers: {
    default: {
      name: 'Explorer',
      url: data?.resources?.explorer,
    },
  },
  faucetLink: data?.resources?.faucet,
  testnet: true,
});

export const getTxLink = (txHash: string, chain: _allWagmiChains.Chain) => ({
  url: chain?.blockExplorers?.default?.url && `${chain?.blockExplorers?.default?.url}/tx/${txHash}`,
  label: `View tx on ${chain?.blockExplorers?.default?.name || 'explorer'}`,
});

/**
 * Compare function for determining whether network info needs to be updated
 * @param networkA
 * @param networkB
 */
export const isSameNetworkInfo = (networkA: CustomChain, networkB: CustomChain) => {
  if (networkA?.id !== networkB?.id) {
    return false;
  }

  if (networkA?.rpcUrls.default.http?.[0] !== networkB.rpcUrls.default.http?.[0]) {
    return false;
  }

  if (networkA?.rpcUrls.public.http?.[0] !== networkB?.rpcUrls.public.http?.[0]) {
    return false;
  }

  return true;
};
