import { Box, CircularProgress, Dialog, useTheme } from '@mui/material';
import NProgress from 'nprogress';
import { FC, useContext, useEffect } from 'react';
import { SidebarContext } from 'src/contexts/SidebarContext';

const Spinner: FC<{ fullWidth: boolean }> = ({ fullWidth }) => {
  const theme = useTheme();

  const { isSidebarCollapsed } = useContext(SidebarContext);

  return (
    <Box
      alignItems="center"
      className="suspense-loader"
      display="flex"
      justifyContent="center"
      sx={{
        position: 'fixed',
        left: fullWidth || isSidebarCollapsed ? 0 : theme.sidebar.width,
        top: 0,
        width: fullWidth || isSidebarCollapsed ? '100%' : `calc(100% - ${theme.sidebar.width})`,
        height: '100%',
      }}
    >
      <CircularProgress disableShrink size={64} thickness={3} />
    </Box>
  );
};

function SuspenseLoader({ fullWidth = false, preventUserActions = false }) {
  useEffect(() => {
    NProgress.start();

    return () => {
      NProgress.done();
    };
  }, []);

  return preventUserActions ? (
    <Dialog open={true}>
      <Spinner fullWidth />
    </Dialog>
  ) : (
    <Spinner fullWidth={fullWidth} />
  );
}

export default SuspenseLoader;
