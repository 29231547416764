import { Grid } from '@mui/material';
import ComingSoonTooltip from 'src/components/ComingSoonTooltip';

import HeaderNotifications from './Notifications';
import HeaderSearch from './Search';
import ThemeSwitcher from './ThemeSwitcher';

function HeaderButtons() {
  return (
    <Grid sx={{ mr: 1, display: { lg: 'flex', xs: 'none' } }}>
      <Grid item sx={{ mx: 0.5 }}>
        <ComingSoonTooltip>
          <HeaderSearch />
        </ComingSoonTooltip>
      </Grid>
      <Grid item sx={{ mx: 0.5 }}>
        <ComingSoonTooltip>
          <HeaderNotifications />
        </ComingSoonTooltip>
      </Grid>
      <Grid item sx={{ mx: 0.5 }}>
        <ComingSoonTooltip>
          <ThemeSwitcher />
        </ComingSoonTooltip>
      </Grid>
    </Grid>
  );
}

export default HeaderButtons;
