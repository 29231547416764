import { SvgIcon } from '@mui/material';

export default function IconAltlayerLong({ height, width }: { height: number; width: number }) {
  return (
    <SvgIcon sx={{ height, width }} viewBox="0 0 346 60">
      <path
        d="M34.2799 58.7747C34.7179 58.7753 35.1518 58.6896 35.5567 58.5222C35.9613 58.3549 36.329 58.1095 36.6385 57.8002L62.7197 31.7663C63.0295 31.457 63.2751 31.09 63.4427 30.6861C63.6103 30.2821 63.6968 29.8492 63.6968 29.4119C63.6968 28.9747 63.6103 28.5417 63.4427 28.1378C63.2751 27.7339 63.0295 27.3668 62.7197 27.0577L36.6385 1.02378C36.329 0.714273 35.9613 0.46885 35.5567 0.301618C35.1518 0.134387 34.7179 0.0486453 34.2799 0.0493204V58.7747Z"
        fill="#6667AB"
      />
      <path
        d="M30.6178 47.8191C29.7645 47.8191 28.9112 47.6181 28.2604 47.2079L2.17618 30.8873C0.874541 30.0728 0.874541 28.7514 2.17618 27.9368L28.2584 11.6163C28.9092 11.209 29.7625 11.0049 30.6158 11.0049L30.6178 47.8191Z"
        fill="#6667AB"
        opacity="0.5"
      />
      <path
        d="M30.6178 36.8133C29.7645 36.8133 28.9112 36.6123 28.2604 36.2031L2.17618 19.8825C0.874541 19.0679 0.874541 17.7465 2.17618 16.9309L28.2584 0.611429C28.9052 0.201128 29.7625 0 30.6178 0V36.8133Z"
        fill="#6667AB"
        opacity="0.5"
      />
      <path
        d="M30.6178 58.8235C29.7645 58.8235 28.9112 58.6225 28.2604 58.2123L2.17618 41.8928C0.874541 41.0771 0.874541 39.7556 2.17618 38.9412L28.2584 22.6207C28.9092 22.2134 29.7625 22.0103 30.6158 22.0103L30.6178 58.8235Z"
        fill="#6667AB"
        opacity="0.5"
      />
      <path
        clipRule="evenodd"
        d="M89.2389 9.31812L73.859 49.5085H84.3621L87.9262 40.3974H101.367L104.994 49.5085H115.5L100.12 9.31812H89.2389ZM99.9917 32.9706H89.3016L94.6158 18.9923L99.9917 32.9706Z"
        fill="#6667AB"
        fillRule="evenodd"
        opacity="0.5"
      />
      <path
        d="M119.011 49.5085V9.31812H129.077V41.7699H148.088V49.5085H119.011Z"
        fill="#6667AB"
        opacity="0.5"
      />
      <path
        clipRule="evenodd"
        d="M216.488 9.31812L201.108 49.5085H211.611L215.175 40.3974H228.616L232.243 49.5085H242.748L227.368 9.31812H216.488ZM227.241 32.9706H216.55L221.864 18.9923L227.241 32.9706Z"
        fill="#6667AB"
        fillRule="evenodd"
      />
      <path
        d="M249.679 49.5085V34.7175L235.233 9.31812H245.736L254.739 25.9815L263.745 9.31812H274.247L259.807 34.7175V49.5085H249.679Z"
        fill="#6667AB"
      />
      <path
        d="M277.619 49.5085V9.31812H307.316V17.0615H287.685V25.4867H305.003V33.3497H287.685V41.775H307.316V49.5133L277.619 49.5085Z"
        fill="#6667AB"
      />
      <path
        clipRule="evenodd"
        d="M310.718 9.31812V49.5085L320.784 49.5104V36.7791H325.412C326.494 36.7771 327.327 36.975 327.912 37.3725C328.494 37.77 328.975 38.4044 329.35 39.2759L333.851 49.5104H344.357L339.605 38.6495C339.284 37.8402 338.896 37.0575 338.45 36.3095C338.072 35.7227 337.595 35.2055 337.038 34.781C339.414 33.7805 341.291 32.2616 342.665 30.2243C344.042 28.1869 344.729 25.8156 344.729 23.1104C344.729 18.9089 343.603 15.5598 341.355 13.0631C339.103 10.5664 335.6 9.31812 330.851 9.31812H310.718ZM328.221 29.0387H320.784V17.0565H328.66C332.537 17.0578 334.474 19.0758 334.474 23.1104C334.474 25.1076 333.921 26.595 332.817 27.5724C331.713 28.5499 330.18 29.0387 328.221 29.0387Z"
        fill="#6667AB"
        fillRule="evenodd"
      />
      <path
        d="M197.676 41.7701H178.67V9.31819H178.599V9.29907H168.524V49.5246H178.599V49.5084H197.676V41.7701Z"
        fill="#6667AB"
      />
      <path
        d="M142.964 17.0565H154.981V49.5085H164.891V9.31812H142.964V17.0565Z"
        fill="#6667AB"
        opacity="0.5"
      />
    </SvgIcon>
  );
}
