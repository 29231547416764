import { SvgIcon } from '@mui/material';

export default function IconFaucet(props: any) {
  return (
    <SvgIcon height="24px" viewBox="0 0 24 24" width="24px" {...props}>
      <path
        clipRule="evenodd"
        d="M14.4979 4.5V5.64372L11.8924 5.643L11.8933 8.50528C13.073 8.6465 14.1202 9.21868 14.8731 10.0599L17.6749 10.0598C20.3068 10.0598 22.4404 12.1933 22.4404 14.8253V18.7965C22.4404 19.2352 22.0848 19.5908 21.6462 19.5908H16.8807C16.442 19.5908 16.0864 19.2352 16.0864 18.7965V17.208C16.0864 16.7694 15.7308 16.4138 15.2922 16.4138L14.8724 16.4145C13.9998 17.389 12.732 18.0023 11.3209 18.0023C9.90986 18.0023 8.64205 17.389 7.76947 16.4145L5.41169 16.4138C5.41169 16.8524 5.05609 17.208 4.61743 17.208H2.23468C1.79603 17.208 1.44043 16.8524 1.44043 16.4138V10.0598C1.44043 9.6211 1.79603 9.26551 2.23468 9.26551H4.61743C5.05609 9.26551 5.41169 9.6211 5.41169 10.0598L7.76876 10.0599C8.52159 9.21868 9.56882 8.6465 10.7485 8.50528L10.7484 5.643L8.14391 5.64372V4.5H14.4979ZM11.3209 9.77126C10.3207 9.77126 9.3919 10.1956 8.73746 10.9268L8.34989 11.3599L5.47243 11.359V15.113L8.35054 15.1146L8.73798 15.5473C9.3924 16.2782 10.321 16.7023 11.3209 16.7023C12.3209 16.7023 13.2494 16.2782 13.9038 15.5473L14.2905 15.1154L15.2922 15.1138C16.4488 15.1138 17.3864 16.0514 17.3864 17.208L17.3854 18.29H21.1394L21.1404 14.8253C21.1404 12.9751 19.6906 11.4636 17.8651 11.3649L17.675 11.3598L14.2919 11.3599L13.9044 10.9268C13.2499 10.1956 12.3211 9.77126 11.3209 9.77126ZM2.58415 10.4092L4.17243 10.409V16.064L2.58415 16.0643V10.4092Z"
        fill="#A4A4B2"
        fillRule="evenodd"
      />
    </SvgIcon>
  );
}
