import DoNotDisturbIcon from '@mui/icons-material/DoNotDisturb';
import LocalGasStationIcon from '@mui/icons-material/LocalGasStation';
import { Box } from '@mui/material';

export default function IconGasless() {
  return (
    <Box position="relative" sx={{ height: '40px', width: '40px' }}>
      <LocalGasStationIcon
        sx={{
          position: 'absolute',
          '&.MuiSvgIcon-root': {
            left: '50%',
            top: '50%',
            transform: 'translate(-50%,-50%)',
            width: '30px',
            height: '30px',
          },
        }}
      />
      <DoNotDisturbIcon
        sx={{
          position: 'absolute',
          '&.MuiSvgIcon-root': {
            left: '50%',
            top: '50%',
            transform: 'translate(-50%,-50%)',
            width: '50px',
            height: '50px',
            opacity: 0.8,
          },
        }}
      />
    </Box>
  );
}
