import { ButtonProps, ListItemButton } from '@mui/material';
import { useConnectModal } from '@rainbow-me/rainbowkit';
import { ElementType, FC, useEffect, useState } from 'react';
import { FlashLayerInfo } from 'src/api/models/flashlayer';
import { getWagmiChainFromFL } from 'src/helpers/wagmi';
import { useAddAndSwitchNetwork } from 'src/hooks/addAndSwitchNetwork';
import { useAccount, useNetwork } from 'wagmi';

interface AddNetworkButtonProps extends ButtonProps {
  data: FlashLayerInfo;
  Component?: ElementType;
  buttonText?: string;
}

const AddNetworkButton: FC<AddNetworkButtonProps> = ({
  Component = ListItemButton,
  buttonText = 'Switch to network',
  data,
  ...rest
}) => {
  const { openConnectModal } = useConnectModal();

  const { isConnected: connected } = useAccount();
  const { chain, chains } = useNetwork();
  const { addAndSwitchNetwork } = useAddAndSwitchNetwork();

  const [wasPromptedToConnect, setWasPromptedToConnect] = useState(false);

  const handleClick = () => {
    if (!connected) {
      openConnectModal();
      setWasPromptedToConnect(true);
    } else {
      // TODO: add network before switching
      console.log('supported chains: ', chains);

      addAndSwitchNetwork(getWagmiChainFromFL(data));
    }
  };

  useEffect(() => {
    if (wasPromptedToConnect && connected) {
      // automatically add network once user has connected their wallet
      setWasPromptedToConnect(false);
      addAndSwitchNetwork(getWagmiChainFromFL(data));
    }
  }, [connected, wasPromptedToConnect, data, addAndSwitchNetwork]);

  return (
    <Component
      disabled={Number(data?.resources?.chainId) === chain?.id}
      onClick={handleClick}
      {...rest}
    >
      {Number(data?.resources?.chainId) === chain?.id ? 'Already on selected network' : buttonText}
    </Component>
  );
};

export default AddNetworkButton;
