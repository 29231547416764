import { Box, Button, Divider, Drawer, styled, useMediaQuery, useTheme } from '@mui/material';
import { useContext, useEffect } from 'react';
import { NavLink } from 'react-router-dom';
import AltlayerLogo from 'src/components/icons/IconAltlayer.tsx';
import Scrollbar from 'src/components/Scrollbar';
import { SidebarContext } from 'src/contexts/SidebarContext';

import IconCollapse from './SidebarMenu/IconCollapse';
import SidebarMenu from './SidebarMenu';

const SidebarWrapper = styled(Box)(
  ({ theme }) => `
        color: ${theme.sidebar.textColor};
        background: ${theme.sidebar.background};
        transition: width 0.2s;
        z-index: 7;
        left: 0;
        top: ${theme.header.height};
        height: 100%;
        position: fixed;
        padding-bottom: 68px;
        .collapse {
          right: -10px;
          top: 15px;
          z-index: 1;
          position: absolute;
          cursor: pointer;
          font-size: 1.25rem;
        }
`,
);

function Sidebar() {
  const { closeSidebar, isSidebarCollapsed, setIsSidebarCollapsed, toggleSidebar } =
    useContext(SidebarContext);
  const theme = useTheme();
  const isLargeScreen = useMediaQuery(theme.breakpoints.up('lg'));

  useEffect(() => {
    setIsSidebarCollapsed(false);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <>
      <SidebarWrapper
        sx={{
          width: isSidebarCollapsed ? theme.sidebar.collapsedWidth : theme.sidebar.width,
          display: {
            xs: 'none',
            lg: 'inline-block',
          },
        }}
      >
        <IconCollapse
          className="collapse"
          onClick={toggleSidebar}
          style={{
            transform: isSidebarCollapsed ? 'scaleX(-1)' : 'none',
          }}
        />
        <Scrollbar>
          <SidebarMenu collapsed={isSidebarCollapsed} />
        </Scrollbar>
        <Divider
          sx={{
            background: theme.functionalColors.divider,
          }}
        />
        <Box p={2}>
          <Button
            color="warning"
            component={NavLink}
            fullWidth
            size="small"
            to="/login"
            variant="contained"
          >
            Sign Out
          </Button>
        </Box>
      </SidebarWrapper>
      <Drawer
        ModalProps={{ BackdropProps: { invisible: true } }}
        anchor={theme.direction === 'rtl' ? 'right' : 'left'}
        elevation={9}
        onClose={closeSidebar}
        open={!isSidebarCollapsed && !isLargeScreen}
        sx={{
          boxShadow: `${theme.sidebar.boxShadow}`,
        }}
        variant="temporary"
      >
        <SidebarWrapper
          sx={{
            top: 0,
            width: theme.sidebar.width,
          }}
        >
          <Scrollbar>
            <Box
              mx={2}
              my={2}
              sx={{
                width: 52,
              }}
            >
              <AltlayerLogo />
            </Box>
            <Divider
              sx={{
                mx: theme.spacing(2),
                background: theme.functionalColors.divider,
              }}
            />
            <SidebarMenu collapsed={false} />
          </Scrollbar>
        </SidebarWrapper>
      </Drawer>
    </>
  );
}

export default Sidebar;
