import { SvgIcon } from '@mui/material';

export default function Icon(props: any) {
  return (
    <SvgIcon height="20px" viewBox="0 0 20 20" width="20px" {...props}>
      <g fill="none" fillRule="evenodd" id="页面-1" stroke="none" strokeWidth="1">
        <g id="Deployments-card" transform="translate(-230.000000, -79.000000)">
          <g id="编组-6" transform="translate(0.000000, 64.000000)">
            <g id="编组-4" transform="translate(230.000000, 15.000000)">
              <circle cx="10" cy="10" fill="#4C4C58" id="椭圆形" r="10"></circle>
              <g id="编组-3">
                <rect height="20" id="矩形" width="20" x="0" y="0"></rect>
                <polygon
                  fill="#DDDDE3"
                  id="Fill-1219"
                  points="12.8416667 13.825 9.025 10 12.8416667 6.175 11.6666667 5 6.66666667 10 11.6666667 15"
                ></polygon>
              </g>
            </g>
          </g>
        </g>
      </g>
    </SvgIcon>
  );
}
